import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useFetch } from '../../../../hooks/useFecth';
import Search from '@mui/icons-material/Search';
import { useCallback, useEffect, useState } from 'react';
import DataGridJoy from '../../../../components/DataGridJoy';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonGroup, Divider, Paper } from '@mui/material';
import DeleteSweepRounded from '@mui/icons-material/DeleteSweepRounded';
import { SearchDrawerBranches } from './SearchDrawerBranches';
import DialogBranch from './DialogBranch';
import { Cancel, CheckCircle } from '@mui/icons-material';
 

export default function ListBranches() {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDrawerOpen, setDrawerOpen] = useState(false); 
    const { data, mutate, isFetching } = useFetch('advertiser', { params: { headOfficeId: id, ...Object.fromEntries(searchParams) } });
    // console.log('aquii', data)
    // const [dialog, setDialog] = useState(false);
    // const [selected, setSelected] = useState();
    
    // const handleCloseDialog = () => {
    //     setDialog(false);
    //     setSelected();
    // };

    // const handleOpenDialog = item => {
    //     setDialog(true);
    //     if (item) setSelected(item);
    // };

    const handleChangePage = useCallback(
        value => {
            console.log(searchParams, 'page');
            setSearchParams({ ...Object.fromEntries(searchParams), page: value });
        },
        [searchParams, setSearchParams],
    );

    const handleChangeRowsPerPage = useCallback(
        value => {
            console.log(searchParams, 'perPage');
            setSearchParams({ ...Object.fromEntries(searchParams), perPage: value });
        },
        [searchParams, setSearchParams],
    );

    // Chamada para atualizar dados quando filtros mudam
        useEffect(() => {
            mutate(); 
        }, [searchParams, mutate]);
    
        // Verifica se há filtros aplicados
        const hasFilters = Array.from(searchParams.entries()).some(
            ([key, value]) => value !== '' && value !== null
        );
    
        // Limpa os filtros aplicados
        const handleClearFilters = () => {
            setSearchParams({});
        };

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack direction="row" gap={1} alignItems="center" pb={2}>
                    <Box py={1}>
                        <Typography level="h4">Filiais do anunciante</Typography>
                    </Box>
                    <Stack direction="row" gap={1} alignItems="center" sx={{ ml: 'auto' }}>
                        <ButtonGroup size='small'>
                            <Button variant="outlined" component={Link} to="#filiais">
                                Adicionar filial
                            </Button>
                            <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                                <Search />
                            </Button>
                            {hasFilters && (
                                <Button color="primary" onClick={handleClearFilters}>
                                    <DeleteSweepRounded />
                                </Button>
                            )}
                        </ButtonGroup>
                    </Stack>
                </Stack>
                <Divider />
                <DataGridJoy
                    rows={data?.rows || []}
                    page={data?.page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={data?.rowsPerPage}
                    numRows={data?.numRows}
                    isLoading={isFetching}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Nome da Filial',
                            flex: 1,
                        },
                        {
                            field: 'createdAt',
                            headerName: 'Criada em',
                            type: 'datetime',
                        },
                        {
                            field: 'status',
                            headerName: 'Status',
                            type: 'boolean',
                            width: 120,
                            render: ({ row }) => (row.status ? <CheckCircle color="success" /> : <Cancel color="error" />),
                        },
                        {
                            field: 'action',
                            headerName: 'Editar',
                            type: 'action',
                            width: 50,
                            render: ({ row }) => (
                                <>
                                    <IconButton variant="outlined" component={Link} to={`#filiais|${row.id}`}>
                                        <EditIcon />
                                    </IconButton>
                                </>
                            ),
                        },
                    ]}
                />
            </Paper>
            <SearchDrawerBranches mutate={mutate} open={isDrawerOpen} onClose={() => setDrawerOpen(false)} onClearFilters={handleClearFilters} />
            <DialogBranch  mutate={mutate} />
        </>
    );
}
