import { Close } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useNavigate } from 'react-router-dom';
import AddImage from '../../../../components/AddImage';
import ConfirmationModal from '../../../../components/ConfirmationModal'; // Importando o modal de confirmação
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';

const PriceField = React.forwardRef(function PriceField(props, ref) {
    const { onChange, value, type, ...other } = props;
    return (
        <IMaskInput
            {...other}
            value={String(value)}
            inputRef={ref}
            onAccept={value => onChange(props.name, value)}
            mask={'R$ num'}
            unmask={true}
            blocks={{
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: '.',
                    radix: ',',
                },
            }}
        />
    );
});

function Edit({ id, mutate }) {
    const { data } = useFetch(`products/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function ListProductsKit({ selected = [], setFieldValue }) {
    const { data, error } = useFetch(`products`, { params: { type: 'additional', paged: false } });

    if (error) return null;

    function handleSelect(id) {
        if (selected.includes(id)) {
            const newlist = selected.filter(i => i !== id);
            setFieldValue('product_kit', newlist);
        } else {
            setFieldValue('product_kit', [...selected, id]);
        }
    }


    return (
        <List>
            {Boolean(data) &&
                Array.isArray(data) &&
                data?.map(item => (
                    <>
                        <ListItem>
                            <ListItemText primary={item.name}/>
                            <ListItemSecondaryAction>
                                <Checkbox onChange={(e, v) => handleSelect(item.id)} checked={selected.includes(item.id)} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </>
                ))}
        </List>
    );
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#plans');
    const { setErrors, enqueueSnackbar } = useErrors();
    const navigate = useNavigate();
    const [tab, setTab] = useState('#dados');

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false); // Estado para controlar o modal de exclusão

    const { values, handleSubmit, handleChange, setFieldValue, touched, errors, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            description: '',
            type: 'plan',
            additionalType: '',
            price: 0,
            // disccount: 0,
            numberLifes: 1,
            image: '',
            product_kit: [],
        },

        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (!values.id) {
                    const { data } = await server.post(`products`, values);
                    enqueueSnackbar(`Plano ${values.name} adicionado`);
                    navigate(`#plans|${data.id}`);
                    handleClose();
                } else {
                    await server.put(`products/${values.id}`, values);
                    enqueueSnackbar(`Plano ${values.name} alterado`);
                    // handleClose();
                }
                await mutate();
            } catch (error) {
                setErrors(error);
            } finally {
                setSubmitting(false);
                handleClose();
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues({ ...data, product_kit: data?.Kit?.map(i => i.productId, []) }, true);
        }
    }, [data]);

    // Função chamada quando a exclusão é confirmada
    const handleDeleteConfirm = async () => {
        try {
            await server.delete(`products/${values.id}`);
            enqueueSnackbar('Plano excluído com sucesso', { variant: 'success' });
            handleClose();
            await mutate();
        } catch (error) {
            setErrors(error);
        } finally {
            setDeleteDialogOpen(false); // Fecha o modal de exclusão
        }
    };

    // Função para abrir o modal de exclusão
    const handleDelete = () => {
        setDeleteDialogOpen(true); // Abre o modal de confirmação
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DialogTitle>{data ? 'Editando plano' : 'Adicionando plano'}</DialogTitle>
                    <IconButton onClick={handleClose} aria-label="close" sx={{ marginRight: 2 }}>
                        <Close />
                    </IconButton>
                </Stack>
                <Divider />
                <TabContext value={tab}>
                    {values.id && (
                        <>
                            <TabList onChange={(e, tab) => setTab(tab)}>
                                <Tab value="#dados" label="Dados do plano" />
                                <Tab value="#adicionais" label="Produtos do plano" />
                            </TabList>
                            <Divider />
                        </>
                    )}
                    <TabPanel value="#dados" sx={{ p: 0 }}>
                        <DialogContent>
                            <Stack spacing={2}>
                                {values.id && (
                                    <AddImage
                                        value={values.previewImage || values.image}
                                        onChange={setFieldValue}
                                        name="image"
                                        label="Adicionar imagem"
                                    />
                                )}
                                <TextField
                                    label="Nome do plano"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    // error={Boolean(touched.name && errors.name)}
                                    // helperText={touched.name && errors.name}
                                />
                                <TextField
                                    label="Descrição do plano"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                                {values.id && (
                                    <>
                                        <TextField
                                            label="Preço"
                                            name="price"
                                            value={String(values.price)}
                                            onChange={setFieldValue}
                                            error={Boolean(touched.price && errors.price)}
                                            helperText={touched.price && errors.price}
                                            type="number"
                                            InputProps={{
                                                inputComponent: PriceField,
                                            }}
                                            InputLabelProps={{
                                                shrink: true/false,
                                            }}
                                        />
                                        <TextField
                                            label="Número de vidas"
                                            name="numberLifes"
                                            value={values.numberLifes}
                                            onChange={handleChange}
                                            error={Boolean(touched.numberLifes && errors.numberLifes)}
                                            helperText={touched.numberLifes && errors.numberLifes}
                                            type="number"
                                        />
                                    </>
                                )}
                            </Stack>
                        </DialogContent>
                    </TabPanel>
                    <TabPanel value="#adicionais" sx={{ p: 0 }}>
                        <DialogContent>
                            {/* <Alert>Colocar aqui a lista de produtos adicionais ordenados por categoria</Alert> */}
                            <ListProductsKit selected={values?.product_kit} setFieldValue={setFieldValue} />
                        </DialogContent>
                    </TabPanel>
                </TabContext>
                <Divider />
                <DialogActions>
                    {values.id && (
                        <Button color="error" onClick={handleDelete}>
                            Excluir
                        </Button>
                    )}
                    <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal de confirmação de exclusão */}
            <ConfirmationModal
                open={isDeleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteConfirm}
                title="Confirmação de Exclusão"
                message={
                    <>
                        Deseja realmente excluir o plano{' '}
                        <Typography component="span" fontWeight="bold">
                            {values.name}
                        </Typography>
                        ?
                    </>
                }
            />
        </>
    );
}

export default function DialogPlans({ mutate }) {
    const { open, id } = useDialog('#plans');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
