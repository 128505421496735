import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useApp } from './AppContext';
import { useCookies } from 'react-cookie';
import server from '../services/server';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

export const AuthProvider = props => {
    const [cookies, setCookies, removeCokie] = useCookies(['token', 'user']);
    const [authenticated, setAuthenticated] = useState(false);
    
    const globalParams = async () => {
        try {
            //FIXME: adicionar essa rota
            // const {data} = await server.get('/auth/publicRules');
            // const current_version = localStorage.getItem('current_version');
            // if( !Boolean(current_version) || current_version !== data.data.front_version){
            //     localStorage.setItem('current_version', data.data.front_version);
            //     window.location.reload()
            // }
        } catch (error) {
            console.log(error);
        }
    };

    const verifySession = async logout => {
        try {
            if (logout) throw 'Logout';
            const token = cookies.token;
            if (!Boolean(token)) throw 'Not session';
            server.defaults.headers['Authorization'] = `Bearer ${token}`;
            setAuthenticated(true);
        } catch (error) {
            console.log(error);
            delete server.defaults.headers.Authorization;
            setAuthenticated(false);
        }
    };

    useEffect(() => {
        verifySession();
        globalParams();
    }, []);

    const Login = async (token, path) => {
        // setCookies('token', token, { path: path || '/' });
        server.defaults.headers['Authorization'] = `Bearer ${token}`;
        setAuthenticated(true);
    };

    const LogOut = () => {
        // setCookies('token', null)
        // setCookies('user', null)
        removeCokie('token');
        removeCokie('refreshtoken');
        removeCokie('user');
        delete server.defaults.headers.Authorization;
        setAuthenticated(false);
        //verifySession(true);
    };

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                user: cookies.user,
                LogOut,
                Login,
                verifySession,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw 'Use Auth Context inside Auth Provider';

    return context;
};
