import React from 'react';
import { IMaskInput } from 'react-imask';

const DocumentInputMasked = React.forwardRef(function DocumentInputMasked(props, ref) {
  const { onChange, name, ...other } = props;

  const mask = [
    { mask: '000.000.000-00', maxLength: 11}, // CPF
    { mask: '00.000.000/0000-00'}, // CNPJ
  ];

  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      mask={mask}
      value={props.value?.toString() || ''}
      unmask={true} // Retorna apenas os números sem formatação
      onAccept={(newValue) => {
        // Envia um evento compatível com React
        onChange({
          target: {
            name, // Nome do campo
            value: newValue, // Valor atualizado
          },
        });
      }}
    />
  );
});

export default DocumentInputMasked;
