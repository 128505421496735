import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
 
export default function SelectCategory({ value, name, onChange, placeholder, label }) {
    const { data, mutate, error, isFetching } = useFetch(`category`, {params:{status: true, all: true}});

    const selected = data?.filter( item=> value === item.id)[0] || null
    
    return (
        <div>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                getOptionKey={e=>e?.id}
                options={data || []}
                value={selected || null}
                name={name}
                loading={isFetching}
                onChange={(e, newValue) => onChange(name, newValue?.id)}
                renderInput={(params) => <TextField {...params} label={placeholder || label} />}
            />
            {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </div>
    );
}
