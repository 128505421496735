import React, { useEffect } from 'react';
import { Box, Drawer, Button, Stack, TextField, FormControl, InputLabel, Select, MenuItem, Typography, IconButton, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import DocumentInputMasked from '../../../../components/DocumentInputMasked';

export function SearchDrawerBranches({ open, onClose, onClearFilters }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { values, handleSubmit, setFieldValue, handleChange, resetForm } = useFormik({
        initialValues: {
            name: searchParams.get('name') || '',
            businessName: searchParams.get('businessName') || '',
            document: searchParams.get('document') || '',
            status: searchParams.get('status') || '',
            state: searchParams.get('state') || '',
            city: searchParams.get('city') || '',
            responsibleName: searchParams.get('responsibleName') || '',
            responsibleEmail: searchParams.get('responsibleEmail') || '',
        },
        onSubmit: (values) => {
            const filteredParams = Object.fromEntries(
                Object.entries(values).filter(([key, value]) => value !== '' && value !== null)
            );
            setSearchParams(filteredParams);
            onClose(); // Fecha o Drawer
        }
    });

    // Usando useEffect para observar mudanças em searchParams
    useEffect(() => {
        // Reseta os valores do Formik sempre que searchParams mudar
        resetForm({
            values: {
                name: searchParams.get('name') || '',
                businessName: searchParams.get('businessName') || '',
                document: searchParams.get('document') || '',
                status: searchParams.get('status') || '',
                state: searchParams.get('state') || '',
                responsibleName: searchParams.get('responsibleName') || '',
                responsibleEmail: searchParams.get('responsibleEmail') || '',
            },
        });
    }, [searchParams, resetForm]);

    const handleClearFilters = () => {
        // Chama a função de exclusão de filtros passada como prop
        onClearFilters();

        // Limpa os parâmetros da URL
        setSearchParams({});

        // Reseta os valores do Formik
        resetForm();
    };

    const hasFilters = Object.values(values).some(value => value !== '' && value !== null);

    const list = () => (
        <Box sx={{ padding: 2, width: 350 }}>
            <DialogTitle sx={{ padding: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                        Buscar Anunciantes
                    <IconButton size="small" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField 
                    fullWidth 
                    label="Nome da filial" 
                    variant="outlined" 
                    name="name" 
                    value={values.name} 
                    onChange={handleChange} />
                    <TextField
                        label="Razão social"
                        variant="outlined"
                        fullWidth
                        name="businessName"
                        value={values.businessName}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth size="small" margin="dense">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select labelId="status-label" id="status" name="status" value={values.status} onChange={handleChange} label="Status">
                            <MenuItem value="true">Ativo</MenuItem>
                            <MenuItem value="false">Inativo</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        fullWidth 
                        label="Documento" 
                        variant="outlined" 
                        name="document" 
                        value={values.document} 
                        onChange={(event) => {setFieldValue('document', event.target?.value || event)}}
                        InputProps={{
                            inputComponent: DocumentInputMasked
                        }}
                    />
                    <TextField label="Estado" variant="outlined" fullWidth name="state" value={values.state} onChange={handleChange} />
                    <TextField label="Cidade" variant="outlined" fullWidth name="city" value={values.city} onChange={handleChange} />
                    <TextField
                        label="Nome do Responsável"
                        variant="outlined"
                        fullWidth
                        name="responsibleName"
                        value={values.responsibleName}
                        onChange={handleChange}
                    />
                    <TextField
                        label="E-mail do Responsável"
                        variant="outlined"
                        fullWidth
                        name="responsibleEmail"
                        value={values.responsibleEmail}
                        onChange={handleChange}
                    />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="contained" type="submit">
                        Buscar
                    </Button>
                    {hasFilters && (
                        <Button variant="outlined" onClick={handleClearFilters}>
                            Limpar Filtros
                        </Button>
                    )}
                </Stack>
            </form>
        </Box>
    );

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            {list()}
        </Drawer>
    );
}
