import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import { Cancel, CheckCircle, DeleteSweepRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Search from '@mui/icons-material/Search';
import { ButtonGroup, Divider, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DataGridJoy from '../../../../components/DataGridJoy';
import { useFetch } from '../../../../hooks/useFecth';
import { SearchDrawerOffers } from '../../Advertiser/components/SearchDrawerOffers';
import DialogOffer from './DialogOffer';

export default function ListOffers() {
    const { id } = useParams();
    const [isDrawerOpen, setDrawerOpen] = useState(false); 
    const [searchParams, setSearchParams] = useSearchParams();
    // const [searchQuery, setSearchQuery] = useState('');
    
    const { data, mutate } = useFetch(`offer`, {
        params: { advertiserId: id, ...Object.fromEntries(searchParams) },
    });

    // Atualização da página
    const handleChangePage = useCallback(
        (value) => {
            setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                page: value,
            }));
        },
        [setSearchParams]
    );

    // Atualização de número de linhas por página
    const handleChangeRowsPerPage = useCallback(
        (value) => {
            setSearchParams((prev) => ({
                ...Object.fromEntries(prev),
                perPage: value,
            }));
        },
        [setSearchParams]
    );

    // Chamada para atualizar dados quando filtros mudam
    useEffect(() => {
        mutate(); 
    }, [searchParams, mutate]);

    // Verifica se há filtros aplicados
    const hasFilters = Array.from(searchParams.entries()).some(
        ([key, value]) => value !== '' && value !== null
    );

    // Limpa os filtros aplicados
    const handleClearFilters = () => {
        setSearchParams({});
    };

    return (
        <Paper sx={{ p: 2 }}>
            <Stack direction="row" gap={1} pb={2} alignItems="center">
                <Box py={1}>
                    <Typography variant="h4">Ofertas do anunciante</Typography>
                </Box>
                <Stack direction="row" gap={1} alignItems="center" sx={{ ml: 'auto' }}>
                    <ButtonGroup size='small'>
                        <Button variant="outlined" component={Link} to="#offer">
                            Adicionar oferta
                        </Button>
                        <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                            <Search />
                        </Button>
                        {hasFilters && (
                            <Button color="primary" onClick={handleClearFilters}>
                                <DeleteSweepRounded />
                            </Button>
                        )}
                    </ButtonGroup>
                </Stack>
            </Stack>
            <Divider />
            <DataGridJoy
                rows={data?.rows || []}
                page={data?.page || 0}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={data?.rowsPerPage || 10}
                numRows={data?.numRows || 0}
                columns={[
                    { field: 'id', headerName: '#ID', width: 50 },
                    { field: 'name', headerName: 'Titulo', flex: 1 },
                    {
                        field: 'updatedAt',
                        headerName: 'Ultima alteração',
                        type: 'datetime',
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        type: 'boolean',
                        width: 120,
                        render: ({ row }) => (row.status ? <CheckCircle color="success" /> : <Cancel color="error" />),
                    },
                    {
                        field: 'action',
                        headerName: 'Ações',
                        type: 'action',
                        width: 50,
                        render: ({ row }) => (
                            <IconButton
                                size="small"
                                component={Link}
                                to={`#offer|${row.id}`}
                            >
                                <EditIcon />
                            </IconButton>
                        ),
                    },
                ]}
            />

            <DialogOffer mutate={mutate} />
            <SearchDrawerOffers
                mutate={mutate}
                open={isDrawerOpen}
                onClose={() => setDrawerOpen(false)}
                onClearFilters={handleClearFilters}
            />
        </Paper>
    );
}