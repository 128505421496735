import { AppBar, Button, ListItemText, Toolbar, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCookies } from 'react-cookie';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Dropdown from '../../components/Dropdown';
import { useApp } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import HelpSidebarProvider from '../../context/HelpSidebarContext';
import { useFetch } from '../../hooks/useFecth';
export default function ClubProtected() {
    const navigate = useNavigate();
    const data = useFetch(`club`)
    const [{ user }] = useCookies();
    const { LogOut, authenticated } = useAuth();
    const { loading } = useApp();

    const isMobile = useMediaQuery('(max-width:600px)');

    if (!authenticated && user) return <>Aguardando autenticação</>;

    return (
        <HelpSidebarProvider>
            <AppBar position="fixed">
                <Toolbar>
                    <Box component="img" src={Logo} width={130} height="auto" sx={{ mr: 2 }} />
                    <Stack direction="row" sx={{ width: '100%' }} columnGap={2} alignItems="center" justifyContent="space-between">
                        <List sx={{ display: isMobile ? 'none' : 'inherit', mr: 'auto' }} variant="horizontal">
                            <ListItemButton component={Link} to="dashboard">
                                <ListItemText primary="Dashboard" />
                                {/* <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon> */}
                            </ListItemButton>
                            <ListItemButton component={Link} to="configuracoes">
                                <ListItemText primary="Configurações" />
                                {/* <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon> */}
                            </ListItemButton>
                        </List>
                        <Stack ml="auto" direction="row" columnGap={1} justifyContent="flex-end" alignContent="center">
                            {/* <FormControl orientation="horizontal" size="sm" sx={{ display: isMobile ? 'none' : 'inherit' }}> */}
                            {/* <IconButton variant='outlined' onClick={e=>setMode(mode === 'light' ? 'dark' : 'light')}>{mode === 'light' ?<DarkModeIcon /> :<LightModeIcon />}</IconButton> */}
                            {/* <FormLabel>{mode === 'dark' ? 'Modo Escuro' : 'Modo Claro'}</FormLabel>
                            <Switch checked={mode === 'dark'} onChange={e => setMode(e.target.checked ? 'dark' : 'light')} /> */}
                            {/* </FormControl> */}
                            <Dropdown>
                                <Button color="inherit" size="small" variant="outlined">
                                    <Box flexDirection="row" display="flex" alignItems="center" gap={2}>
                                        <Typography color="white" variant="caption">
                                            {/* {user?.userName} */}
                                            <b>{data?.data?.name}</b>
                                        </Typography>
                                        <Avatar sx={{ width: 25, height: 25 }} />
                                        {/* <ExpandMore /> */}
                                    </Box>
                                </Button>
                                <Menu placement="bottom-end">
                                    {/* <MenuItem onClick={e => navigate('configuracoes/cargos')}>Cargos</MenuItem> */}
                                    <MenuItem onClick={e => navigate('configuracoes/usuarios')}>Usuários</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/categorias')}>Categorias</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/adicionais')}>Adicionais</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/planos')}>Planos</MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={e => {
                                            LogOut();
                                            navigate('/login', { replace: true });
                                        }}
                                    >
                                        Sair
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Paper sx={{ minHeight: '100vh', paddingTop: '60px', backgroundColor: '#efefef47' }}>
                <Outlet />
            </Paper>
        </HelpSidebarProvider>
    );
}
