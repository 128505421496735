import { useSnackbar } from 'notistack';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function useErrors() {
    const { verifySession, LogOut } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const setErrors = errors => {
        if (errors) {
            if (!Boolean(errors)) {
                enqueueSnackbar('Serviço indisponível', { variant: 'error' });
                return;
            }

            if (!Boolean(errors.response)) {
                enqueueSnackbar('Sistema sem resposta', { variant: 'error' });
                return;
            }

            const { status } = errors.response;

            if (status === 401) {
                if (errors.response.data.error == 'refresh_token_reject') {
                    navigate('/', { replace: true });
                    LogOut();
                    enqueueSnackbar('Faça o login novamente.', { variant: 'error' });
                }

                return;
            }

            if (status === 404) {
                enqueueSnackbar('Caminho não encontrado', { variant: 'error' });
                return;
            }

            if (errors.response.data) {
                if (Array.isArray(errors.response.data.message)) {
                    errors.response.data.message.forEach(item => {
                        enqueueSnackbar(item.message, { variant: 'error' });
                    });

                    return;
                }

                if (errors.response.data.message) {
                    enqueueSnackbar(errors.response.data.message, { variant: 'error' });
                    return;
                }
            }

            if (errors.response.message) {
                enqueueSnackbar(errors.response.data, { variant: 'error' });
                return;
            }

            enqueueSnackbar('Servidor indisponível. Já estamos revisando!', { variant: 'warning' });
            return;
        }
    };

    return { setErrors, enqueueSnackbar };
}
