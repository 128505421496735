import { ChevronRight } from '@mui/icons-material';
import { Button, Chip, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFecth';
import DialogUser from './components/DialogUser';

export default function User() {
    const { data, mutate } = useFetch('users');
    console.log(data)

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Usuários</Typography>
            </Box>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" columnGap={2} p={2}>
                    <Button variant="outlined" component={Link} to={`#users`}>Adicionar usuário</Button>
                </Stack>
                <Divider />
                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 1 }}>
                            {data?.rows?.map(item => (
                                <React.Fragment key={`list_${item.id}`}>
                                    <ListItem 
                                    secondaryAction={
                                        <IconButton component={Link} to={`#users|${item.id}`} title="Abrir">
                                            <ChevronRight />
                                        </IconButton>
                                    }>
                                            <Box>
                                                <Typography variant="title-md">{item?.userName}</Typography>
                                                <Chip
                                                    sx={{borderRadius: 1, marginTop: 1}}
                                                    size="small"
                                                    color="primary"
                                                    label={item?.Team.name}
                                                />
                                                <Chip
                                                    sx={{borderRadius: 1, marginTop: 1, marginLeft: 1}}
                                                    size="small"
                                                    color={item?.status ? 'success' : 'error'} 
                                                    label={`Status: ${item?.status ? 'Ativo' : 'Inativo'}`} 
                                                />
                                            </Box>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogUser mutate={mutate} />
        </Container>
    );
}
