import Stack from '@mui/material/Stack';
import { useParams, useSearchParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useFetch } from '../../../hooks/useFecth';
import Search from '@mui/icons-material/Search';
import { useCallback, useState } from 'react';
import DataGridJoy from '../../../components/DataGridJoy';
import EditIcon from '@mui/icons-material/Edit';
import { Paper } from '@mui/material';

export default function ListRescues() {
    const { id } = useParams();
    const [searchParams, setParams] = useSearchParams();
    const [dialog, setDialog] = useState(false);
    const { data, error, mutate, isFetching } = useFetch('club/rescue', { params: { clubId: id, ...Object.fromEntries(searchParams) } });
    const [selected, setSelected] = useState();
    const handleCloseDialog = () => {
        setDialog(false);
        setSelected();
    };

    const handleOpenDialog = item => {
        setDialog(true);
        if (item) setSelected(item);
    };

    const handleChangePage = useCallback(
        value => {
            console.log(searchParams, 'page');
            setParams({ ...Object.fromEntries(searchParams), page: value });
        },
        [searchParams],
    );

    const handleChangeRowsPerPage = useCallback(
        value => {
            console.log(searchParams, 'perPage');
            setParams({ ...Object.fromEntries(searchParams), perPage: value });
        },
        [searchParams],
    );

    return (
        <Paper sx={{p:2}}>
            <Stack direction="row" gap={1} alignItems="center">
                {/* <Box py={1}>
                    <Typography level="h4">Assinantes do clube</Typography>
                </Box> */}
                <Stack direction="row" gap={1} p={1} alignItems="center" sx={{ ml: 'auto' }}>
                    <Button variant="outlined" onClick={e => handleOpenDialog()}>
                        Adicionar assinante
                    </Button>
                    <IconButton variant="outlined">
                        <Search />
                    </IconButton>
                </Stack>
            </Stack>
            <DataGridJoy
                rows={data?.rows || []}
                page={data?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={data?.rowsPerPage}
                numRows={data?.numRows}
                isLoading={isFetching}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Titulo',
                        flex: 1,
                    },
                    {
                        field: 'createdAt',
                        headerName: 'Criada em',
                        type: 'datetime',
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        type: 'boolean',
                        width: 120,
                    },
                    {
                        field: 'action',
                        headerName: '#',
                        type: 'action',
                        width: 50,
                        render: ({ row }) => (
                            <>
                                <IconButton size="sm" variant="outlined" onClick={e => handleOpenDialog(row)}>
                                    <EditIcon />
                                </IconButton>
                            </>
                        ),
                    },
                ]}
            />
        </Paper>
    );
}
