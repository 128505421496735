import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

export default function AddContacts({ onImport, loading }) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (!loading) {
      setOpen(false);
      setFile(null); // Limpa o arquivo selecionado ao fechar o modal.
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile?.name.endsWith('.xlsx')) {
      setFile(uploadedFile);
    } else {
      setFile(null); // Redefinir arquivo se o formato for inválido.
    }
  };

  const handleImport = () => {
    if (!file) return;
    onImport(file); // Dispara a importação.
  };

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Importar Contatos
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginRight={2}>
          <DialogTitle>Importar Contatos</DialogTitle>
          <IconButton size="small" onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <Typography variant="body1" align="center">
              Faça upload de um arquivo <b>.xlsx</b> contendo seus contatos.
            </Typography>
            <Box
              component="label"
              sx={{
                cursor: 'pointer',
                border: 'dashed 2px #ccc',
                borderRadius: 1,
                width: 420,
                height: 120,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <input type="file" accept=".xlsx" style={{ display: 'none' }} onChange={handleFileChange} />
              <Typography>
                Clique aqui e selecione seu arquivo.
              </Typography>
            </Box>
            {file && (
              <Typography variant="body2" color="textPrimary">
                Arquivo selecionado: {file.name}
              </Typography>
            )}
            {loading && <CircularProgress size={30} />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={handleImport} color="primary" variant="contained" disabled={!file || loading}>
            {loading ? 'Importando...' : 'Importar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
