import Stack from '@mui/material/Stack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import server from '../services/server';
import { useAuth } from '../context/AuthContext';

export default function AuthRedirect() {
    const [params] = useSearchParams();
    const [cookies, setCookies] = useCookies(['token', 'user']);
    const navigation = useNavigate();
    const { Login } = useAuth();

    const Authentication = async token => {
        const { data } = await server.get('auth', {
            headers: {
                Authorization: `Bearer ${params.get('token')}`,
            },
        });

        const credentials = {
            token: params.get('token'),
            refreshtoken: params.get('refreshtoken'),
            user: data.data,
            club: params.get('club'),
        };

        if (params.get('club')) {
            Login(credentials.token, '/clube');
            setCookies('token', credentials.token, { path: '/clube' });
            setCookies('refreshtoken', credentials.refreshtoken, { path: '/clube' });
            setCookies('user', credentials.user, { path: '/clube' });
            setCookies('club', credentials.club, { path: '/clube' });
            navigation('/clube', { replace: true });
        } else {
            Login(credentials.token, '/dashboard');
            setCookies('token', credentials.token, { path: '/dashboard' });
            setCookies('refreshtoken', credentials.refreshtoken, { path: '/dashboard' });
            setCookies('user', credentials.user, { path: '/dashboard' });
            navigation('/dashboard', { replace: true });
        }
    };

    useEffect(() => {
        Authentication();
    }, []);

    return (
        <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
            Carregando Dados
        </Stack>
    );
}
