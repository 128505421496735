import { useFetch } from '../hooks/useFecth';
import { Checkbox, List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';

export default function SelectCategoriesList({ value = [], name, onChange, placeholder, label }) {
    const { data, mutate, error, isFetching } = useFetch(`category`, { params: { status: true } });

    const [opened, setOpened] = useState();

    const toogleCollapse = id => {
        if (opened === id) {
            setOpened();
        } else {
            setOpened(id);
        }
    };

    const setSelectedCategories = data => {
        onChange(name, data);
    };

    const toggleCategory = category => {
        const isSelected = value.includes(category.id);
        const newSelected = [...value];

        if (isSelected) {
            // Se estiver selecionado, remove a mãe e todas as filhas
            removeCategoryAndChildren(category, newSelected);
        } else {
            // Se não estiver selecionado, adiciona a mãe e todas as filhas
            addCategoryAndChildren(category, newSelected);
        }

        setSelectedCategories(newSelected);
    };

    // Adiciona categoria e suas filhas recursivamente
    const addCategoryAndChildren = (category, selected) => {
        if (!selected.includes(category.id)) {
            selected.push(category.id);
        }
        //category.SubCategorys.forEach(sub => addCategoryAndChildren(sub, selected));
    };

    // Remove categoria e suas filhas recursivamente
    const removeCategoryAndChildren = (category, selected) => {
        const index = selected.indexOf(category.id);
        if (index > -1) {
            selected.splice(index, 1);
        }
        //category.SubCategorys.forEach(sub => removeCategoryAndChildren(sub, selected));
    };
    const renderCategories = (categories, level = 0) => {
        return categories?.map(category => (
            <React.Fragment key={category.id}>
                <ListItem
                    style={{ paddingLeft: `${level * 20}px` }}
                    // secondaryAction={
                    //     <>
                    //         {Boolean(category.SubCategorys) && Boolean(category?.SubCategorys.length) && (
                    //             <IconButton onClick={e => toogleCollapse(category.id)}>
                    //                 <ArrowDropDownCircle />
                    //             </IconButton>
                    //         )}
                    //     </>
                    // }
                >
                    <Checkbox checked={value.includes(category.id)} onClick={() => toggleCategory(category)} />
                    <ListItemText primary={category.name} primaryTypographyProps={{variant: 'h6'}} />
                </ListItem>
                {/* <Collapse in={opened === category.id}> */}
                    {Boolean(category.SubCategorys) && Boolean(category?.SubCategorys.length) && renderCategories(category?.SubCategorys, level + 1)}
                {/* </Collapse> */}
            </React.Fragment>
        ));
    };
    return <List>{renderCategories(data)}</List>;
}