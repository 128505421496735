import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import AppRoutes from './Routes';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import '@fontsource/inter';
import '@fontsource-variable/quicksand';
import { ptBR } from 'date-fns/locale/pt-BR';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryComponent } from './components/Content';

// const joyTheme = extendTheme({
//     components: {
//         JoyTabList: {
//             styleOverrides: {
//                 root: ({ theme }) => {
//                     return {
//                         gap: theme.spacing(1),
//                         fontWeight: 900,
//                     };
//                 },
//             },
//         },
//         JoyTab: {
//             styleOverrides: {
//                 root: ({ theme }) => ({
//                     borderRadius: theme.vars.radius.sm,
//                 }),
//             },
//         },
//         JoyListItemButton: {
//             styleOverrides: {
//                 root: ({ theme }) => ({
//                     borderRadius: theme.vars.radius.sm,
//                 }),
//             },
//         },
//         JoyList: {
//             styleOverrides: {
//                 root: ({ theme }) => {
//                     return {
//                         gap: theme.spacing(1),
//                         fontWeight: 900,
//                     };
//                 },
//             },
//         },
//         JoyButton: {
//             styleOverrides: {
//                 root: {
//                     fontWeight: 900,
//                 },
//             },
//         },
//         JoyTypography: {
//             styleOverrides: {
//                 root: ({ className }) => {
//                     if (className.includes('title-')) {
//                         return { fontWeight: 900 };
//                     }
//                 },
//             },
//         },
//     },
//     fontFamily: {
//         display: 'Quicksand Variable',
//         body: 'Quicksand Variable',
//     },
// });

function App() {

    const navigate = useNavigate()
    const theme = createTheme(
        {
            shape: {
                borderRadius: 6,
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        size: 'small',
                    },
                    styleOverrides: {
                        root: {
                            borderRadius: 6,
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        size: 'small',
                    },
                    styleOverrides: {
                        root: {
                            borderRadius: 2,
                        },
                    },
                },
                MuiMenuItem: {
                    defaultProps: {
                        dense: true,
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        size: 'small',
                    },
                },
                MuiSelect: {
                    defaultProps: {
                        size: 'small',
                    },
                },
                MuiIconButton: {
                    defaultProps: {
                        size: 'small',
                    },
                },
                MuiCard: {
                    defaultProps: {
                        variant: 'outlined',
                        style: {
                            backgroundColor: 'white',
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        title: {
                            fontSize: '1rem',
                            fontWeight: 800,
                        },
                    },
                },
                MuiPaper: {
                    defaultProps: {
                        variant: 'outlined',
                    },
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            fontWeight: 700,
                        },
                    },
                },
                MuiList: {
                    variants: [
                        {
                            props: { variant: 'horizontal' },
                            style: ({ theme }) => ({
                                // display: 'flex',
                                // flexDirection: 'row',
                                // overflowX: 'scroll',
                                // width: '350px',
                                padding: '8px',
                                // borderRadius: theme.shape.borderRadius,
                                // border: 1,
                                '& .MuiListItemIcon-root': {
                                    minWidth: '20px',
                                    color: 'inherit',
                                },
                                '& .MuiListItem-root': {
                                    padding: 0,
                                    minWidth: 50,
                                    '&.active': {
                                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                                    },
                                },
                                '& .MuiListItemText-root': {
                                    textAlign: 'center',
                                },
                            }),
                        },
                    ],
                },
            },
            // palette: {
            //     primary: {
            //         main: '#0a6475',
            //     },
            // },
            typography: {
                fontFamily: 'Quicksand Variable',
                h1: {
                    fontSize: '2rem',
                    fontWeight: '700',
                    color: 'pallete.primary.main',
                },
                h2: {
                    fontSize: '1.8rem',
                    fontWeight: '800',
                },
                h3: {
                    fontSize: '1.4rem',
                    fontWeight: '700',
                },
                h4: {
                    fontSize: '1.2rem',
                    fontWeight: '800',
                },
                h6: {
                    fontSize: '1.0rem',
                    fontWeight: '500',
                },
                bolded: {
                    fontWeight: '600',
                    color: 'primary.main',
                },
                dashboardBolded: {
                    fontWeight: '600',
                    color: 'primary.main',
                    fontSize: '1.5rem',
                },
                report: {
                    fontWeight: '500',
                    fontSize: '2.3rem',
                    lineHeight: 1,
                },
                'title-sm': {
                    fontWeight: '700',
                    fontSize: '0.8rem',
                    display: 'block',
                },
                'subtitle-sm': {
                    fontWeight: '400',
                    fontSize: '1rem',
                    display: 'block',
                },
                'title-md': {
                    fontWeight: '700',
                    fontSize: '1.1rem',
                    display: 'block',
                },
                'subtitle-md': {
                    fontWeight: '400',
                    fontSize: '1rem',
                    display: 'block',
                },
                'title-lg': {
                    fontWeight: '700',
                    fontSize: '2.1rem',
                    display: 'block',
                },
                'subtitle-lg': {
                    fontWeight: '400',
                    fontSize: '2rem',
                    display: 'block',
                },
            },
        },
        ptBR,
    );

    const handleReset = ()=>{
        navigate(-1, {replace: true})
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryComponent} onReset={handleReset}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <CookiesProvider>
                    <AppProvider>
                        <AuthProvider>
                            <ConfirmProvider>
                                <SnackbarProvider autoHideDuration={3000} variant="success" anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <AppRoutes />
                                </SnackbarProvider>
                            </ConfirmProvider>
                        </AuthProvider>
                    </AppProvider>
                </CookiesProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
