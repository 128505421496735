import { MenuOpenRounded } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVert from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Avatar, CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import { LoadingContent } from '../../components/Loading';
import { useFetch } from '../../hooks/useFecth';
import BannersClub from './components/BannersClub';
import DialogClub from './components/DialogClub';
import ListRescues from './components/ListRescues';
import ListSubscribers from './components/ListSubscribers';
import MenuClub from './components/MenuClub';
import ProductsClub from './components/ProductsClub';
import SettingsClub from './components/SettingsClub';

export default function ClubeDetails() {
    const [tab, setTab] = useState('#tab-1');
    const { data, error, mutate, isFetching } = useFetch(`club`);

    if (isFetching) return <LoadingContent />;
    if (error) return <>Error: {error?.message}</>;
    if (!data) return <>Tente novamente mais tarde</>;

    return (
        <Container sx={{ gap: 3, my: 4, flexDirection: 'column', display: 'flex', pb: 4 }}>
            <Stack alignItems="center" direction="row" columnGap={1} py={3}>
                {/* <IconButton component={Link} to={-1} replace={true}>
                    <ChevronLeft />
                </IconButton> */}
                <Typography variant="h2">Detalhes do clube</Typography>
            </Stack>

            <Card>
                <CardContent sx={{ rowGap: 2, display: 'flex', flexDirection: 'column' }}>
                    <Stack direction="row" alignItems="center" columnGap={2}>
                        <Avatar variant="rounded" sx={{ border: '1px solid #ccc', objectFit: 'contain', width: 100, height: 85 }} src={data.logo} />
                        <div>
                            <Typography variant="h4">{data.name}</Typography>
                            <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                                {data.businessName}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ fontStyle: 'italic' }}>
                                {data.ClubCategory.length} categorias
                            </Typography>
                        </div>

                        <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
                            <Dropdown>
                                <IconButton>
                                    <MoreVert />
                                </IconButton>
                                <Menu placement="bottom-start">
                                    <MenuItem component={Link} to={`#club|${data.id}`}>
                                        Alterar dados
                                    </MenuItem>
                                    {/* <MenuItem color="danger">Excluir</MenuItem> */}
                                    <Divider />
                                    <MenuItem color="danger">Mig ID: {data.originId}</MenuItem>
                                </Menu>
                            </Dropdown>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Grid container columnGap={2}>
                        <Grid item xs={12} sm={6} md textAlign="center">
                            <Typography level="title-sm">Status</Typography>
                            <Chip
                                size="small"
                                label={data.status ? 'Ativo' : 'Inativo'}
                                color={data.status ? 'success' : 'error'}
                                icon={<FiberManualRecordIcon htmlColor="green" />}
                            />
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Plano</Typography>
                            <Typography> {data.Plan?.name || 'Sem plano'} </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        {/* <Grid item xs={12} sm={6} md>
                        <Typography level="title-sm">Tipo</Typography>
                        <Typography>João Paulo</Typography>
                    </Grid> 
                    <Divider orientation="vertical" flexItem />*/}
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Responsável</Typography>
                            <Typography>{data.responsibleName || '--'} </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Contato</Typography>
                            <Typography> {data.responsibleEmail || '--'} </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <TabContext value={tab}>
                <TabList onChange={(e, tab) => setTab(tab)}>
                    <Tab icon={<PeopleIcon />} iconPosition="start" value="#tab-1" label="Assinantes" />
                    <Tab icon={<StorefrontIcon />} iconPosition="start" value="#tab-2" label="Planos" />
                    <Tab icon={<PercentIcon />} iconPosition="start" value="#tab-3" label="Resgates" />
                    <Tab icon={<ViewCarouselIcon />} iconPosition="start" value="#tab-4" label="Banners" />
                    <Tab icon={<MenuOpenRounded />} value="#tab-5" iconPosition="start" label="Menu" />
                    <Tab icon={<SettingsApplicationsIcon />} value="#tab-6" iconPosition="start" label="Configurações" />
                    {/* <Tab icon={<MoneyRounded />} value="#tab-6" iconPosition="start" label="Contrato" /> */}

                    {/* <Tab icon={<ReceiptIcon />} value="#tab-6"  iconPosition="start" label="Assinatura" /> */}
                </TabList>
                <TabPanel value="#tab-1" sx={{ p: 0 }}>
                    <ListSubscribers />
                </TabPanel>
                <TabPanel value="#tab-2" sx={{ p: 0 }}>
                    <ProductsClub />
                </TabPanel>
                <TabPanel value="#tab-3" sx={{ p: 0 }}>
                    <ListRescues />
                </TabPanel>
                <TabPanel value="#tab-4" sx={{ p: 0 }}>
                    <BannersClub />
                </TabPanel>
                <TabPanel value="#tab-5" sx={{ p: 0 }}>
                    <MenuClub />
                </TabPanel>
                <TabPanel value="#tab-6" sx={{ p: 0 }}>
                    <SettingsClub data={data} mutate={mutate} />
                </TabPanel>
            </TabContext>

            <DialogClub mutate={mutate} />
        </Container>
    );
}
