import React, { useEffect } from 'react';
import { Box, Drawer, Button, Stack, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, IconButton, DialogTitle, Typography, FormGroup, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

export function SearchDrawerOffers({ open, onClose, onClearFilters }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { values, handleSubmit, handleChange, resetForm } = useFormik({
        initialValues: {
            name: searchParams.get('name') || '',
            status: searchParams.get('status') || '',
            isCoupon: searchParams.get('isCoupon') === true || false,
            isCashback: searchParams.get('isCashback') === true || false,
            isVoucher: searchParams.get('isVoucher') === true || false,
        },
        onSubmit: (values) => {
            const filteredParams = Object.fromEntries(
                Object.entries(values).filter(([key, value]) => value !== '' && value !== null)
            );
        
            if (values.status) {
                filteredParams.status = values.status === 'true';
            }
        
            setSearchParams(filteredParams);
            console.log('Parâmetros atualizados na URL:', filteredParams);
            onClose();
        }        
    });

    useEffect(() => {
        // console.log('searchParams:', Object.fromEntries(searchParams.entries()));
        resetForm({
            values: {
                name: searchParams.get('name') || '',
                status: searchParams.get('status') || '',
                isCoupon: searchParams.get('isCoupon') === true || false,
                isCashback: searchParams.get('isCashback') === true || false,
                isVoucher: searchParams.get('isVoucher') === true || false,
            },
        });
    }, [searchParams, resetForm]);

    const handleClearFilters = () => {
        onClearFilters();
        setSearchParams({});
        resetForm();
    };

    const hasFilters = Object.entries(values).some(([key, value]) => {
        if (typeof value === 'boolean') {
            return value === true; // Considera apenas checkboxes marcados
        }
        return value !== '' && value !== null; // Verifica strings e outros valores
    });    

    const list = () => (
        <Box sx={{ padding: 2, width: 350 }}>
            <DialogTitle sx={{ padding: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                    Buscar Assinantes
                    <IconButton size="small" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField 
                        fullWidth 
                        label="Nome da Oferta" 
                        variant="outlined" 
                        name="name" 
                        value={values.name} 
                        onChange={handleChange} 
                    />
                    <FormControl fullWidth size="small" margin="dense">
                        <InputLabel id="status-label">Status da Oferta</InputLabel>
                        <Select labelId="status-label" id="status" name="status" value={values.status} onChange={handleChange} label="Status da Oferta">
                            <MenuItem value="true">Ativo</MenuItem>
                            <MenuItem value="false">Inativo</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <Typography variant='title-md' my={1}>Tipo da Oferta</Typography>
                        <FormControlLabel control={<Checkbox checked={values.isCoupon} onChange={handleChange} name="isCoupon" />} label="Cupom" />
                        <FormControlLabel control={<Checkbox checked={values.isCashback} onChange={handleChange} name="isCashback" />} label="Cashback" />
                        <FormControlLabel control={<Checkbox checked={values.isVoucher} onChange={handleChange} name="isVoucher" />} label="Voucher" />
                    </FormGroup>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="contained" type="submit">
                        Buscar
                    </Button>
                    {hasFilters && (
                        <Button variant="outlined" onClick={handleClearFilters}>
                            Limpar Filtros
                        </Button>
                    )}
                </Stack>
            </form>
        </Box>
    );

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            {list()}
        </Drawer>
    );
}