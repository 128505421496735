import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { FormHelperText } from '@mui/material';
import { TextField } from '@mui/material';
import { useState } from 'react';

function Search(params={}){
    
    
}
 
export default function SelectAdvertisers({ value=[], name, onChange, placeholder, label, list }) {
    
    const { data =[], mutate, error, isFetching } = useFetch(`advertiser`, {params:{ status: true, paged: false }});
    
    const handleSelect = (selected)=>{
        onChange(name, selected.map( i=>i.id) )
    }

    const selected = data?.filter( item=> value && value.includes(item.id))
    
    return (
        <div>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name}
                getOptionKey={e=>e.id}
                options={data || []}
                value={selected}
                name={name}
                limitTags={3}
                multiple
                loading={isFetching}
                onChange={(e, newValue) => handleSelect(newValue)}
                renderInput={(params) => <TextField {...params} label={placeholder || label} />}
            />
            {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </div>
    );
}
