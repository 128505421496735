import { Close } from '@mui/icons-material';
import { Box, Dialog, Divider, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import server from '../../../../services/server';
import AddImage from '../../../../components/AddImage';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import ConfirmationModal from '../../../../components/ConfirmationModal';

const CommissionField = React.forwardRef(function CommissionField(props, ref) {
    const { onChange, value, ...other } = props;

    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            value={String(value)}
            onAccept={(value) => onChange(props.name, value)}
            mask={'R$ num'}  // Define a máscara corretamente
            unmask={true}  // Remove a máscara ao retornar o valor
            blocks={{
                num: {
                    mask: Number,
                    scale: 4,
                    thousandsSeparator: '.',
                    radix: ',',
                    min: 0,
                    max: 10000,
                },
            }}
        />
    );
});

// const isPercentual = (is) => {
//     if (is) {
//         return {
//             mask: 'num%',
//             unmask: true,
//             blocks: {
//                 num: {
//                     mask: Number,
//                     scale: 4,
//                     radix: ',',
//                     min: 0,
//                     max: 100,
//                 },
//             },
//         };
//     } else {
//         return {
            
//         };
//     }
// };

function Edit({ id, mutate }) {
    const { data } = useFetch(`products/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#additional');
    const {setErrors, enqueueSnackbar} = useErrors();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            description: '',
            type: 'additional',
            additionalType: '',
            priceCost: '',
            priceSuggestion: '',
            price: '',
            disccount: '',
            image: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`products`, values);
                    enqueueSnackbar(`Plano ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`products/${values.id}`, values);
                    enqueueSnackbar(`Plano ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const handleDelete = () => {
        setConfirmAction(() => async () => {
            try {
                await server.delete(`products/${values.id}`);
                enqueueSnackbar('Produto excluido com sucesso!');
                await mutate();
                handleClose()
            } catch (error) {
                setErrors(error);
            }
            setConfirmOpen(false);
        });
        setConfirmOpen(true);
    };

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DialogTitle>{data ? 'Editando produto' : 'Adicionando produto'}</DialogTitle>
                    <IconButton onClick={handleClose} aria-label="close" sx={{ marginRight: 2 }}>
                        <Close />
                    </IconButton>
                </Stack>

                <Divider />
                <DialogContent component="form" onSubmit={handleSubmit}>
                    <Stack rowGap={2}>
                        <AddImage
                            value={values.image || values.previewImage} // Usando o valor correto para o preview
                            onChange={setFieldValue} // Atualizando o estado do Formik com o arquivo
                            label="Adicionar imagem"
                        />
                        <TextField onChange={handleChange} label="Nome do produto" value={values.name} name="name" />
                        <TextField onChange={handleChange} label="Descrição do produto" value={values.description} name="description" />
                        <TextField
                            onChange={setFieldValue}
                            label="Preço"
                            value={values.price} // Garante que o valor é uma string
                            name="price"
                            InputProps={{
                                inputComponent: CommissionField,
                            }}
                        />
                        <TextField
                            onChange={setFieldValue}
                            label="Preço de custo"
                            value={values.priceCost} // Garante que o valor é uma string
                            name="priceCost"
                            InputProps={{
                                inputComponent: CommissionField,
                            }}
                        />
                        <TextField
                            onChange={setFieldValue}
                            label="Preço sugerido ao clubber"
                            value={values.priceSuggestion} // Garante que o valor é uma string
                            name="priceSuggestion"
                            InputProps={{
                                inputComponent: CommissionField,
                            }}
                        />
                        <FormControl>
                            <InputLabel size="small">Tipo de adicional</InputLabel>
                            <Select onChange={handleChange} label="Tipo de adicional" name="additionalType" value={values.additionalType}>
                                <MenuItem value="telemedicina">Telemedicina</MenuItem>
                                <MenuItem value="telepsicologia">Telepsicologia</MenuItem>
                                <MenuItem value="segurodevida">Seguro de vida</MenuItem>
                                <MenuItem value="funerario">Funerário</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {Boolean(values.id) && (
                            <Box>
                                <Button variant="outlined" color="error" onClick={handleDelete}>
                                    Excluir
                                </Button>
                            </Box>
                        )}
                        <Box>
                            <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                                Salvar
                            </Button>
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>
            <ConfirmationModal
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={confirmAction}
                title="Confirmação de Exclusão"
                message="Tem certeza de que deseja excluir esta oferta?"
            />
        </>
    );
}

export default function DialogPlans({ mutate }) {
    const { open, id } = useDialog('#additional');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}