import { BlockOutlined, ChevronRight, DeleteSweepRounded, Search } from '@mui/icons-material';
import { Avatar, Button, ButtonGroup, Chip, IconButton, ListItemAvatar, ListItemText, Pagination, Paper, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFecth';
import DialogMigrate from '../../DialogMigrate';
import DialogClubs from './components/DialogClubs';
import { SearchDrawerClubs } from './components/SearchDrawerClubs';

export default function Clubs() {
    // const navigation = useNavigate();
    const [isDrawerOpen, setDrawerOpen] = useState(false); // Controle de estado do drawer
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(''); // Estado para armazenar o texto da pesquisa
    const { data, mutate } = useFetch('clubs', { params: { ...Object.fromEntries(searchParams) } });
    // console.log('dados do club', data)

    useEffect(() => {
            mutate(); // Executar a consulta
        }, [searchParams, searchQuery, mutate]);
        // console.log('Parâmetros enviados ao backend:', Object.fromEntries(searchParams));
    
    const handlePage = (event, page) => {
        // Atualiza os parâmetros da URL, mantendo todos os outros e apenas mudando o parâmetro 'page'
        setSearchParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('page', page); // Define o número da página na URL
            return newParams;
        });
    };
    
    // console.log(Object.fromEntries(searchParams))

    const hasFilters = Array.from(searchParams.entries()).some(([key, value]) => value !== '' && value !== null);

    const handleClearFilters = () => {
        setSearchParams({});
    };

    // Verificação para exibir uma mensagem caso não haja resultados
    useEffect(() => {
        if (data?.rows?.length === 0) {
            // console.log('Nenhum resultado encontrado para a pesquisa:', searchQuery);
        }
    }, [data, searchQuery]);

    return (
        <Container sx={{ my: 4 }}>
            <Stack py={3}>
                <Typography variant="h1">Clubes</Typography>
            </Stack>

            <Paper>
                <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                    <Button variant="outlined" component={Link} to="#migration|club">
                        Migrar clube
                    </Button>
                    <Button variant="outlined" component={Link} to="#clubs">
                        Adicionar clube
                    </Button>
                    <ButtonGroup>
                        <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                            <Search/>
                        </Button>
                        {hasFilters && (
                        <Button color="primary" onClick={handleClearFilters}>
                            <DeleteSweepRounded />
                        </Button>
                        )}
                    </ButtonGroup>
                </Stack>
                <Divider />

                <List size="md">
                    <>
                        {/* {!Boolean(data) && !Boolean(data?.rows.length) && (
                            <ListItem>
                                <ListItemText primary="Nenhum item listado" />
                            </ListItem>
                        )} */}

                    {data?.rows?.length === 0 ? (
                    <Paper sx={{ p: 4, border: 0 }}>
                        <Stack direction="row" justifyContent="center" gap={1}>
                            <Typography>Nenhum clube encontrado.</Typography>
                            <BlockOutlined />
                        </Stack>
                    </Paper>
                    ) : 
                        data?.rows?.map(item => (
                            <React.Fragment key={`list_${item.id}`}>
                                <ListItem secondaryAction={
                                    <IconButton component={Link} to={`${item.id}`} title="Abrir">
                                        <ChevronRight />
                                    </IconButton>}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={item.logo} variant='rounded'  sx={{ marginRight: 2, border: '1px solid #eee', objectFit: 'cover', width: 60, height: 60 }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.name}
                                        primaryTypographyProps={{ variant: 'title-md' }}
                                        secondary={
                                            <Stack columnGap={1} direction="row" py={1}>
                                                <Chip
                                                    size="small"
                                                    color={item.status ? 'success' : 'error'}
                                                    label={item.status ? 'Ativo' : 'Inativo'}
                                                />
                                            </Stack>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </>

                </List>
                <Stack direction="row" py={2} justifyContent="center">
                    <Pagination page={data?.page || 1} count={data?.pages} onChange={handlePage} />
                </Stack>
            </Paper>

            <DialogClubs mutate={mutate} />
            <DialogMigrate mutate={mutate} from="club" />
            <SearchDrawerClubs open={isDrawerOpen} onClose={() => setDrawerOpen(false)} onClearFilters={handleClearFilters} />
        </Container>
    );
}