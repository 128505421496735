import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

export function LoadingContent() {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <Backdrop 
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} 
        open={open} 
        onClick={handleClose}>
            <CircularProgress color="inherit" />
        </Backdrop>
        
    );
}
