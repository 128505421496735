import { BlockOutlined, Cancel, CheckCircle, DeleteSweepRounded, SearchRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonGroup, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import DataGridJoy from '../../../components/DataGridJoy';
import { useFetch } from '../../../hooks/useFecth';
import DialogSubscriber from './DialogSubscriber';
import { SearchDrawerSubscriber } from './SearchDrawerSubscriber';
import AddContacts from '../../../components/AddContacts';
import server from '../../../services/server';
import useErrors from '../../../hooks/useErrors';

export default function ListSubscribers({ clubId }) {
    const { id } = useParams();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const { setErrors, enqueueSnackbar } = useErrors();

    const { data, mutate, isFetching } = useFetch('club/subscribers', {
        params: { clubId: id, ...Object.fromEntries(searchParams) },
    });

    useEffect(() => {
        mutate();
    }, [searchParams, searchQuery, mutate]);

    const handleChangePage = value => {
        setSearchParams(prev => ({
            ...Object.fromEntries(prev),
            page: value,
        }));
    };

    const handleChangeRowsPerPage = value => {
        setSearchParams(prev => ({
            ...Object.fromEntries(prev),
            perPage: value,
        }));
    };

    const handleClearFilters = () => {
        setSearchParams({});
    };

    const hasFilters = Array.from(searchParams.entries()).some(([key, value]) => value !== '' && value !== null);

    const handleImport = file => {
        setLoading(true); // Ativa o estado de carregamento no início do processo.

        const formData = new FormData();
        formData.append('file', file);

        server.post('club/subscribers/import', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(response => {
                enqueueSnackbar('Contatos importados com sucesso.', { variant: 'success' });
                mutate(); // Atualiza os dados após a importação.
                setLoading(false); // Finaliza o estado de carregamento.
            })
            .catch(error => {
                setErrors(error);
                enqueueSnackbar('Erro ao importar contatos.', { variant: 'error' });
                setLoading(false); // Finaliza o estado de carregamento.
            });
    };

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack direction="row" gap={1} alignItems="center">
                    <Stack direction="row" gap={1} mb={2} alignItems="center" sx={{ ml: 'auto' }}>
                        {/* <InsertApi /> */}
                        <AddContacts onImport={handleImport} loading={loading} /> {/* Passa a função de importação */}
                        <ButtonGroup size="small">
                            <Button size="small" variant="outlined" component={Link} to="#assinantes">
                                Adicionar assinante
                            </Button>
                            <Button onClick={() => setDrawerOpen(true)}>
                                <SearchRounded />
                            </Button>
                            {hasFilters && (
                                <Button color="primary" onClick={handleClearFilters}>
                                    <DeleteSweepRounded />
                                </Button>
                            )}
                        </ButtonGroup>
                    </Stack>
                </Stack>
                {data?.rows?.length === 0 ? (
                    <Paper sx={{ p: 4 }}>
                        <Stack direction="row" justifyContent="center" gap={1}>
                            <Typography>Nenhum resultado encontrado para a pesquisa.</Typography>
                            <BlockOutlined />
                        </Stack>
                    </Paper>
                ) : (
                    <DataGridJoy
                        rows={data?.rows || []}
                        page={data?.page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={data?.rowsPerPage}
                        numRows={data?.numRows}
                        isLoading={isFetching}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'Nome',
                                type: 'name',
                            },
                            {
                                field: 'email',
                                headerName: 'E-mail',
                                flex: 1,
                            },
                            {
                                field: 'status',
                                headerName: 'Status',
                                type: 'boolean',
                                width: 120,
                                render: ({ row }) => (row.status ? <CheckCircle color="success" /> : <Cancel color="error" />),
                            },
                            {
                                field: 'action',
                                headerName: 'Editar',
                                type: 'action',
                                width: 50,
                                render: ({ row }) => (
                                    <IconButton component={Link} to={`#assinantes|${row.id}`} size="sm" variant="outlined">
                                        <EditIcon />
                                    </IconButton>
                                ),
                            },
                        ]}
                    />
                )}
            </Paper>
            <DialogSubscriber mutate={mutate} clubId={clubId} dataa={data} />
            <SearchDrawerSubscriber open={isDrawerOpen} onClose={() => setDrawerOpen(false)} onClearFilters={handleClearFilters} />
        </>
    );
}
