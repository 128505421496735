import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, MenuItem, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import Address from '../../../components/Address';
import useDialog from '../../../hooks/useDialog';
import useErrors from '../../../hooks/useErrors';
import { useFetch } from '../../../hooks/useFecth';
import server from '../../../services/server';
import PhoneInputMasked from '../../../components/PhoneInputMasked';
import DocumentInputMasked from '../../../components/DocumentInputMasked';

function Edit({ id, mutate }) {
    const { data } = useFetch(`club`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#club');
    const {setErrors, enqueueSnackbar} = useErrors();

    const { values, handleSubmit, handleChange, errors, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            demo: false,
            description: '',
            document: '',
            businessName: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            goias: '',
            country: 'BR',
            supportPhone: '',
            supportEmail: '',
            status: '',
            Owner: {
                name: '',
                email: '',
                document: '',
                birthday: '',
                gender: '',
                phone: '',
            },

        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`club`, values);
                    enqueueSnackbar(`Clube ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`club`, values);
                    enqueueSnackbar(`Clube ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const {id, name, status, businessName, description, document, zipcode, street, number, complement, neighborhood, city, state, country, ClubCategory, Owner } = data;
            
            const formattedBirthday = Owner?.birthday 
            ? new Date(Owner.birthday).toLocaleDateString('en-CA') 
            : null;


            setValues(
                {
                    id,
                    name,
                    status,
                    description,
                    document,
                    zipcode,
                    street,
                    number,
                    complement,
                    neighborhood,
                    city,
                    state,
                    businessName,
                    country,
                    address: `${street}, ${neighborhood}, ${city}-${state}`,
                    categoryId: ClubCategory.map(i => i.categoryId),
                    Owner: {
                        ...Owner,
                        birthday: formattedBirthday,
                    }
                },
                true,
            );
        }
    }, [data, setValues]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando clube</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    {/* {Boolean(values.id) && (
                        <>
                            <AddImage name='logo' onChange={setFieldValue} value={values.previewImage || values.logo} />
                        </>
                    )} */}

                    <TextField
                        label="Nome do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Nome do dono clube"
                        name="Owner.name"
                        value={values.Owner?.name}
                    />

                    <TextField
                        label="Email do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Email do dono clube"
                        name="Owner.email"
                        value={values.Owner?.email}
                    />

                    <TextField
                        label="Documento do responsável pelo clube"
                        onChange={event => {
                            setFieldValue('Owner.document', event.target?.value || event);
                        }}
                        placeholder="Documento do dono clube"
                        name="Owner.document"
                        value={values.Owner?.document}
                        InputProps={{
                            inputComponent: DocumentInputMasked,
                        }}
                    />

                    <TextField
                        label="Gênero do responsável pelo clube"
                        onChange={handleChange}
                        name="Owner.gender"
                        value={values.Owner?.gender}
                        select
                        fullWidth
                        variant="outlined"
                        placeholder="Gênero do dono do Clube"
                    >
                        <MenuItem value="male">Masculino</MenuItem>
                        <MenuItem value="female">Feminino</MenuItem>
                    </TextField>

                    <TextField
                        required
                        label="Telefone do responsável pelo clube"
                        onChange={event => setFieldValue('Owner.phone', event.target?.value || event)}
                        placeholder="Telefone do clube"
                        name="Owner.phone"
                        value={values.Owner?.phone}
                        error={Boolean(errors.phone)} // Exibe o estado de erro
                        helperText={errors.phone} // Mensagem de erro personalizada
                        InputProps={{
                            inputComponent: PhoneInputMasked,
                        }}
                    />

                    <TextField
                        label="Aniversário do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Aniversário"
                        name="Owner.birthday"
                        type="date"
                        InputLabelProps={{
                            shrink: true / false,
                        }}
                        value={values.Owner?.birthday}
                    />

                    <Divider />

                    <TextField label="Nome do clube" onChange={handleChange} placeholder="Nome do clube" name="name" value={values.name} />

                    {Boolean(values.id) && (
                        <>
                            <TextField
                                label="Razão Social"
                                onChange={handleChange}
                                placeholder="Razão social"
                                name="businessName"
                                value={values.businessName}
                            />

                            <TextField
                                label="Documento"
                                onChange={handleChange}
                                placeholder="Documento"
                                name="document"
                                value={values.document}
                                InputProps={{
                                    inputComponent: DocumentInputMasked,
                                }}
                            />
                            <Address
                                onChange={handleChange}
                                setFieldValue={setFieldValue}
                                name="zipcode"
                                setFieldError={setFieldError}
                                value={values.zipcode}
                                address={values.address}
                            />
                            <Stack direction="row" columnGap={2}>
                                <TextField fullWidth onChange={handleChange} label="Complemento" name="complement" value={values.complement} />
                                <TextField sx={{ width: 150 }} onChange={handleChange} label="Número" name="number" value={values.number} />
                            </Stack>

                            {/* <SelectCategories
                                value={values.categoryId}
                                name="categoryId"
                                onChange={setFieldValue}
                                label="Selecione as categorias"
                            /> */}
                        </>
                    )}

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                orientation="horizontal"
                                label="Status do clube"
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogClub({ mutate }) {
    const { open, id } = useDialog('#club');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
