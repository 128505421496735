import React, { createContext, useContext, useState } from 'react';
import Help from '@mui/icons-material/Help';
import { Box, DialogContent, DialogTitle, Divider, Paper, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';

const HelperSidebarContext = createContext({});

const helpers = {
    offer_rules:
        'As ofertas tem um campo de regras que pode ser preenchido com os detalhes a respeito de regras específicas do plano com texto plano.',
    offer_avaliableQty: (
        <>
            <Typography sx={{mb: 1}}>
                Existem três tipos de oferta: <b>Cupom</b>, <b>Cashback</b> e <b>Voucher</b>. Você pode selecionar <b>Cupom</b> e <b>Cashback</b>{' '}
                juntos, mas <b>não é permitido combinar com Voucher</b>.
            </Typography>
            <Typography sx={{mb: 1}}>
                O <b>Título da oferta</b> define o nome da oferta. <br />
            </Typography>
            <Typography sx={{mb: 1}}>
                No campo de <b>descrição</b>, adicione detalhes. <br />A <b>descrição privada</b> é visível apenas pelo proprietário. <br />
            </Typography>
            <Typography sx={{mb: 1}}>
                No campo de <b>regras gerais</b> você define as regras da oferta em questão.
            </Typography>
            <Typography sx={{mb: 1}}>
                A <b>quantidade disponível</b> é definida de acordo com a quantidade existente na oferta. <br />
                Quando o limite for atingido, a oferta fica <b>indisponível</b>.
            </Typography>
            <Typography sx={{mb: 1}}>
                Se escolher <b>Cupom</b>, defina o <b>valor do desconto</b> (fixo ou porcentagem).
            </Typography>
            <Typography sx={{mb: 1}}>
                Se selecionar <b>Cashback</b> com <b>Cupom</b>, será possível definir o valor dos dois tanto <b>Cupom, quanto Cashback</b> (fixo ou porcentagem).
            </Typography>
            <Typography>
                Se escolher <b>Voucher</b>, defina o <b>valor do voucher</b> (fixo ou porcentagem).
            </Typography>
        </>
    ),
    offer_urlOpenExternal:
        'O site da oferta pode ser aberto dentro do próprio app ou pode abrir no navegador do usuário. Algumas ofertas obrigatóriamente não permitem rastreamento abrindo dentro do app. Por isso selecione essa opção para esses casos.',
};

export default function HelpSidebarProvider(props) {
    const [open, setDialog] = useState();

    const handleCloseDialog = () => {
        setDialog();
    };

    const handleOpenDialog = item => {
        setDialog(helpers[item]);
    };

    return (
        <HelperSidebarContext.Provider
            value={{
                openHelp: handleOpenDialog,
            }}
        >
            <>
                {props.children}
                <Drawer
                    sx={{ zIndex: 9999 }}
                    PaperProps={{
                        sx: {
                            bgcolor: 'transparent',
                            p: { md: 2, sm: 0 },
                            boxShadow: 'none',
                            maxWidth: 350,
                        },
                    }}
                    role="presentation"
                    anchor="right"
                    variant="temporary"
                    open={Boolean(open)}
                    onClose={handleCloseDialog}
                    //hideBackdrop
                >
                    <Paper
                        variant="outlined"
                        sx={{ borderRadius: 'md', height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}
                    >
                        <DialogTitle sx={{ display: 'inline-flex', columnGap: 1 }}>
                            <Help /> Ajuda
                        </DialogTitle>
                        <Divider />

                        <DialogContent>
                            <Typography>{open}</Typography>
                        </DialogContent>
                    </Paper>
                </Drawer>
            </>
        </HelperSidebarContext.Provider>
    );
}

export const useHelper = () => {
    const context = useContext(HelperSidebarContext);
    if (!context) throw 'useApp context inside corret provider';

    return context;
};
