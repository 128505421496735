import { BlockRounded } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, List, ListItem, ListItemText, Paper, Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useDialog from '../../../hooks/useDialog';
import useErrors from '../../../hooks/useErrors';
import { useFetch } from '../../../hooks/useFecth';
import server from '../../../services/server';
function Form({ data, mutate }) {
    const { id } = useParams();
    const { open, handleClose } = useDialog('#menu');
    const [errs, setErrs] = useState();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            label: '',
            link: '',
            isExternal: '',
            onWeb: false,
            onMobile: false,
            status: '',
            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`club/settings/menu`, values);
                    enqueueSnackbar(`Item adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`club/settings/menu/${values.id}`, values);
                        enqueueSnackbar(`Item removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`club/settings/menu/${values.id}`, values);
                        enqueueSnackbar(`Item alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({ title: 'Removendo menu', description: 'Você confirma que está removendo o item?' });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data, setValues]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando menu</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField label="Titulo" onChange={handleChange} name="label" value={values.label} />
                    <TextField label="Link" onChange={handleChange} name="link" value={values.link} />

                    <FormControlLabel
                        control={<Switch checked={values.onMobile} onChange={e => setFieldValue('onMobile', e.target.checked)} />}
                        orientation="horizontal"
                        label="Para o app"
                    />
                    <FormControlLabel
                        control={<Switch checked={values.onWeb} onChange={e => setFieldValue('onWeb', e.target.checked)} />}
                        orientation="horizontal"
                        label="Para o site"
                    />
                    {values.onMobile && (
                        <FormControlLabel
                            control={<Switch checked={values.isExternal} onChange={e => setFieldValue('isExternal', e.target.checked)} />}
                            orientation="horizontal"
                            label="Abrir fora do app?"
                        />
                    )}

                    {Boolean(values.id) && (
                        <FormControlLabel
                            control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                            label="Status do item"
                        />
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    const { id: clubId } = useParams();
    const { data } = useFetch(`clubs/${clubId}/menu/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#menu');

    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}
export default function MenuClub() {
    const { id } = useParams();
    const { data, mutate } = useFetch(`club/settings/menu`);

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#menu" variant="outlined">
                        Adicionar Menu
                    </Button>
                </Stack>
                <Card>
                    <List>
                        {!!data && !Boolean(data.length) && (
                            <Paper sx={{ border: 0, p: 4 }}>
                                <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                    <Typography>Nenhum resultado encontrado.</Typography>
                                    <BlockRounded />
                                </Stack>
                            </Paper>
                        )}
                        {data?.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#menu|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={item.label}
                                        primaryTypographyProps={{ variant: 'h4' }}
                                        secondary={
                                            <>
                                                <Stack direction='row' gap={1} mt={1}>
                                                    {item.onMobile && <Chip size="small" label="Aplicativo" color="primary" />}
                                                    {item.onWeb && <Chip size="small" label="Site" color="primary" />}
                                                    {item.status ? <Chip size="small" label="Ativo" color="success" /> : <Chip size="small" label="Inativo" color="error" />}
                                                </Stack>
                                            </>
                                        }
                                    />
                                    <Divider />
                                </ListItem>
                                {/* <Divider /> */}
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}