import React, { useState } from 'react';
import Table from '@mui/material/Table';
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    Link,
    MenuItem,
    Paper,
    Select,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/Check';
import EmptyIcon from '@mui/icons-material/Close';

function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`;
}

// function descendingComparator(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function getComparator(order, orderBy) {
//     return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
// }
// function stableSort(array, comparator) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) {
//             return order;
//         }
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map(el => el[0]);
// }

// EnhancedTableHead.propTypes = {
//     numSelected: PropTypes.number.isRequired,
//     onRequestSort: PropTypes.func.isRequired,
//     onSelectAllClick: PropTypes.func.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//     orderBy: PropTypes.string.isRequired,
//     rowCount: PropTypes.number.isRequired,
// };

// function EnhancedTableHead(props) {
//     const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
//     const createSortHandler = property => event => {
//         onRequestSort(event, property);
//     };

//     return (
//         <thead>
//             <tr>
//                 <th>
//                     <Checkbox
//                         indeterminate={numSelected > 0 && numSelected < rowCount}
//                         checked={rowCount > 0 && numSelected === rowCount}
//                         onChange={onSelectAllClick}
//                         slotProps={{
//                             input: {
//                                 'aria-label': 'select all desserts',
//                             },
//                         }}
//                         sx={{ verticalAlign: 'sub' }}
//                     />
//                 </th>
//                 {headCells.map(headCell => {
//                     const active = orderBy === headCell.id;
//                     return (
//                         <th key={headCell.id} aria-sort={active ? { asc: 'ascending', desc: 'descending' }[order] : undefined}>
//                             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
//                             <Link
//                                 underline="none"
//                                 color="neutral"
//                                 textColor={active ? 'primary.plainColor' : undefined}
//                                 component="button"
//                                 onClick={createSortHandler(headCell.id)}
//                                 fontWeight="lg"
//                                 startDecorator={headCell.numeric ? <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} /> : null}
//                                 endDecorator={!headCell.numeric ? <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} /> : null}
//                                 sx={{
//                                     '& svg': {
//                                         transition: '0.2s',
//                                         transform: active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
//                                     },
//                                     '&:hover': { '& svg': { opacity: 1 } },
//                                 }}
//                             >
//                                 {headCell.label}
//                                 {active ? (
//                                     <Box component="span" sx={visuallyHidden}>
//                                         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                                     </Box>
//                                 ) : null}
//                             </Link>
//                         </th>
//                     );
//                 })}
//             </tr>
//         </thead>
//     );
// }

// function EnhancedTableToolbar(props) {
//     const { numSelected } = props;

//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 py: 1,
//                 pl: { sm: 2 },
//                 pr: { xs: 1, sm: 1 },
//                 ...(numSelected > 0 && {
//                     bgcolor: 'background.level1',
//                 }),
//                 borderTopLeftRadius: 'var(--unstable_actionRadius)',
//                 borderTopRightRadius: 'var(--unstable_actionRadius)',
//             }}
//         >
//             {numSelected > 0 ? (
//                 <Typography sx={{ flex: '1 1 100%' }} component="div">
//                     {numSelected} selected
//                 </Typography>
//             ) : (
//                 <Typography level="body-lg" sx={{ flex: '1 1 100%' }} id="tableTitle" component="div">
//                     Nutrition
//                 </Typography>
//             )}

//             {numSelected > 0 ? (
//                 <Tooltip title="Delete">
//                     <IconButton size="sm" color="danger" variant="solid">
//                         <DeleteIcon />
//                     </IconButton>
//                 </Tooltip>
//             ) : (
//                 <Tooltip title="Filter list">
//                     <IconButton size="sm" variant="outlined" color="neutral">
//                         <FilterListIcon />
//                     </IconButton>
//                 </Tooltip>
//             )}
//         </Box>
//     );
// }

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const DataGridJoy = function ({ handleChangeRowsPerPage, handleChangePage, page=0, rows, numRows=0, columns, isLoading }) {
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const typeColumn = (type, value) => {
        switch (type) {
            case 'datetime':
                return new Date(value).toLocaleString('pt-BR');
                break;
            case 'boolean':
                return value ? <Chip size="small" color="success" icon={<CheckIcon />} /> : <Chip size="small" color="error" icon={<EmptyIcon />} />;
                break;
            case 'action':
                return value;
                break;
            default:
                return value;
                break;
        }
    };

    const changeRowsPerPage = e => {
        setRowsPerPage(e.target.value);
        handleChangeRowsPerPage && handleChangeRowsPerPage(e.target.value);
    };

    const getLabelDisplayedRowsTo = () => {
        if (numRows === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1 ? numRows : Math.min(numRows, (page + 1) * rowsPerPage);
    };

    return (
        <TableContainer component={Paper}>
            {isLoading && <LinearProgress />}
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        {columns?.map((col, index) => (
                            <TableCell
                                sx={{
                                    fontWeight: 900,
                                    flex: col.flex || 'inherit',
                                    width: col.width || 'initial',
                                    textAlign: col.align || 'initial',
                                }}
                                key={`h_${index}`}
                            >
                                {col.headerName || col.field}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!Boolean(rows) || !Boolean(rows.length) && (
                        <TableRow>
                            <TableCell align='center' colSpan={columns.length}>Nenhum item</TableCell>
                        </TableRow>
                    )}
                    {Boolean(rows) &&
                        rows?.map((row, index) => (
                            <TableRow key={`row_${index}`}>
                                {columns?.map((col, index) => {
                                    if (Boolean(col.render)) {
                                        return (
                                            <TableCell style={{ textAlign: col.align || 'initial' }} key={`col_${index}`}>
                                                {col.render({ row, value: row[col.field] })}
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell style={{ textAlign: col.align || 'initial' }} key={`col_${index}`}>
                                            {typeColumn(col.type || '', row[col.field])}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'flex-end',
                                    py: 2,
                                }}
                            >
                                <FormControl orientation="horizontal" size="sm" variant='standard'>
                                    {/* <InputLabel>
                                        Total de itens: {numRows || 0} - Items por página: {rows.length}
                                    </InputLabel> */}
                                    <Select onChange={changeRowsPerPage} defaultValue={10} value={rowsPerPage}>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                                    {labelDisplayedRows({
                                        from: numRows === 0 ? 0 : page * rowsPerPage + 1,
                                        to: getLabelDisplayedRowsTo(),
                                        count: numRows === -1 ? -1 : numRows,
                                    })}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton variant="outlined" disabled={page === 1} onClick={() => handleChangePage(page - 1)}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <IconButton
                                        variant="outlined"
                                        disabled={numRows !== -1 ? page >= Math.ceil(numRows / rowsPerPage) - 1 : false}
                                        onClick={() => handleChangePage(page + 1)}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

DataGridJoy.propTypes = {
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    page: PropTypes.number,
    columns: PropTypes.arrayOf(
        PropTypes.objectOf({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string,
            type: PropTypes.oneOf[('boolean', 'image', 'string', 'number', 'action', 'datetime')],
            render: PropTypes.func,
            flex: PropTypes.number,
        }),
    ),
};

export default DataGridJoy;
