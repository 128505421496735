import useSWR from 'swr';
import { useApp } from '../context/AppContext';
import server from '../services/server';
import useErrors from './useErrors';

export function useFetch(url, params) {
    const { setLoading } = useApp();
    const { setErrors } = useErrors();
    const { data, error, mutate } = useSWR(
        { url, params },
        async ({ url, params }) => {
            setLoading(true);
            const response = await server.get(url, params);
            setLoading(false);

            return response?.data;
        },
        {
            errorRetryCount: 2,
            errorRetryInterval: 10000
        },
    );
    if (error) {
        // console.log(error);
        setErrors(error);
    }

    return { data: data?.data, error, mutate, isFetching: !data && !error };
}
