import { Close } from '@mui/icons-material';
import { Box, Dialog, Divider, FormControlLabel, IconButton, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ConfirmationModal from '../../../../components/ConfirmationModal'; // Importando o novo modal de exclusão
import SelectCategory from '../../../../components/SelectCategory';
import useDialog from '../../../../hooks/useDialog';
import useErrors from '../../../../hooks/useErrors';
import { useFetch } from '../../../../hooks/useFecth';
import server from '../../../../services/server';

function Edit({ id, mutate }) {
    const { data } = useFetch(`category/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const {setErrors, enqueueSnackbar} = useErrors();
    const { open, handleClose } = useDialog('#category');

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false); // Controla a abertura do modal de exclusão

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            childOf: '',
            clubId: '',
            status: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`category`, values);
                    enqueueSnackbar(`Categoria ${values.name} adicionada`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`category/${values.id}`, values);
                    enqueueSnackbar(`Categoria ${values.name} alterada`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    // Função chamada quando a exclusão é confirmada
    const handleDeleteConfirm = async () => {
        if (!values.id) return; // Garante que apenas categorias existentes sejam excluídas

        try {
            await server.delete(`category/${values.id}`);
            enqueueSnackbar(`Categoria ${values.name} excluída`, { variant: 'success' });
            await mutate(); // Atualiza os dados após exclusão
            handleClose(); // Fecha o diálogo
        } catch (error) {
            enqueueSnackbar('Erro ao excluir categoria', { variant: 'error' });
            setErrors(error);
        } finally {
            setDeleteDialogOpen(false); // Fecha o modal de exclusão após a confirmação
        }
    };

    const handleDelete = () => {
        setDeleteDialogOpen(true); // Abre o modal de confirmação de exclusão
    };

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <>
            <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DialogTitle>{data ? 'Editando categoria' : 'Adicionando categoria'}</DialogTitle>
                    <IconButton onClick={handleClose} aria-label="close" sx={{ marginRight: 2 }}>
                        <Close />
                    </IconButton>
                </Stack>

                <Divider />
                <DialogContent component="form" onSubmit={handleSubmit}>
                    <Stack rowGap={2}>
                        <TextField
                            onChange={handleChange}
                            placeholder={`Nome da categoria`}
                            value={values.name}
                            name="name"
                        />
                        <SelectCategory
                            onChange={setFieldValue}
                            placeholder="Categoria pai"
                            name="childOf"
                            value={values.childOf}
                        />
                        <FormControlLabel
                            control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                            label="Status"
                        />
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {Boolean(values.id) && (
                            <Box>
                                <Button variant="outlined" color="error" onClick={handleDelete}>
                                    Excluir
                                </Button>
                            </Box>
                        )}
                        <Box>
                            <Button variant="contained" loading={isSubmitting} onClick={handleSubmit} type="submit">
                                Salvar
                            </Button>
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>

            {/* Modal de confirmação de exclusão */}
            <ConfirmationModal
                open={isDeleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteConfirm}
                title="Confirmação de Exclusão"
                message={
                    <>
                        Você deseja realmente excluir a categoria{' '}<Typography component="span" fontWeight="bold">{values.name}</Typography>?
                    </>
                }  
            />
        </>
    );
}

export default function DialogCategory({ mutate }) {
    const { open, id } = useDialog('#category');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
