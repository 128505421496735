import { Block } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Avatar, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, Switch, Tab, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddImage from '../../../components/AddImage';
import useDialog from '../../../hooks/useDialog';
import useErrors from '../../../hooks/useErrors';
import { useFetch } from '../../../hooks/useFecth';
import server from '../../../services/server';

const PriceField = React.forwardRef(function PriceField(props, ref) {
    const { onChange, value, type, ...other } = props;
    return <IMaskInput {...other} value={String(value)} inputRef={ref} onAccept={value => onChange(props.name, value)} 
        mask={'R$ num'}
        unmask={true}
        blocks={{
            num: {
                mask: Number,
                scale: 2,
                thousandsSeparator: '.',
                radix: ',',
            },
        }} 
    />;
});

function ListProductsKit({ selected = [], setFieldValue }) {
    const { data, error } = useFetch(`club`);
    // console.log('aqui tem o data:', data)
    if (error) return null;

    function handleSelect(id) {
        if (selected.includes(id)) {
            const newlist = selected.filter(i => i !== id);
            setFieldValue('product_kit', newlist);
        } else {
            setFieldValue('product_kit', [...selected, id]);
        }
    }


    return (
        <List>
            {Boolean(data) &&
                Array.isArray(data?.Plan?.Kit) &&
                data?.Plan?.Kit?.map(item => (
                    <>
                        <ListItem>
                            <ListItemText primary={item?.Product?.name}/>
                            <ListItemSecondaryAction>
                                <Checkbox onChange={(e, v) => handleSelect(item?.productId)} checked={selected.includes(item?.productId)} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </>
                ))}
        </List>
    );
}

function Form({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#planos');
    const [tab, setTab] = useState('#dados');
    const navigation = useNavigate();
    const {setErrors, enqueueSnackbar} = useErrors()
    const confirm = useConfirm();
    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            description: '',
            type: 'plan',
            price: 0,
            disccount: '',
            image: '',
            product_kit: [],
            status: '',
            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    const { data } = await server.post(`club/settings/plans`, { ...values, price: Number(values.price) });
                    navigation(`#planos|${data?.data?.id}`, { replace: true });
                    enqueueSnackbar(`Plano adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`club/settings/plans/${values.id}`, values);
                        enqueueSnackbar(`Plano removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`club/settings/plans/${values.id}`, { ...values, price: Number(values.price) });
                        enqueueSnackbar(`Plano alterado`);
                    }

                    resetForm();
                    handleClose();
                }

                await mutate();
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const handleDelete = async () => {
        try {
          // Exibe o diálogo de confirmação
          await confirm({
            title: 'Remover plano?',
            description: 'Essa ação é permanente. Você confirma a remoção?',
          });
      
          // Se confirmado, executa as ações
          setFieldValue('action', 'delete');
          handleSubmit();
        } catch (error) {
         setErrors(error)
        }
      };

    useEffect(() => {
        if (data) {
            
            setValues({ ...data, product_kit: data.Kit.map(i => i.productId), action: 'update' }, true);
        }
    }, [data, setValues]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando planos</DialogTitle>
            <Divider />
            <TabContext value={tab}>
                {values.id && (
                    <>
                        <TabList onChange={(e, tab) => setTab(tab)}>
                            <Tab value="#dados" label="Dados do plano" />
                            <Tab value="#adicionais" label="Produtos do plano" />
                        </TabList>
                        <Divider />
                    </>
                )}
                <TabPanel value="#dados" sx={{ p: 0 }}>
                    <DialogContent>
                        <Stack gap={2}>
                            <TextField onChange={handleChange} label={`Nome do plano`} value={values.name} name="name" />
                            {values.id && (
                                <AddImage
                                    value={values.previewImage || values.image}
                                    onChange={setFieldValue}
                                    name="image"
                                    label="Adicionar imagem"
                                />
                            )}

                            <TextField onChange={handleChange} label={`Descrição do plano`} value={values.description} name="description" />
                            <TextField
                                onChange={setFieldValue}
                                label="Preço de venda"
                                value={values.price}
                                name="price"
                                type="number"
                                InputProps={{
                                    inputComponent: PriceField,
                                }}
                                InputLabelProps={{
                                    shrink: true/false,
                                }}
                            />

                            {values.id && (
                                <>
                                    <FormControlLabel
                                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                        orientation="horizontal"
                                        label="Status"
                                    />
                                </>
                            )}
                        </Stack>
                    </DialogContent>
                </TabPanel>
                <TabPanel value="#adicionais" sx={{ p: 0 }}>
                    <DialogContent>
                        {/* <Alert>Colocar aqui a lista de produtos adicionais ordenados por categoria</Alert> */}
                        <ListProductsKit selected={values?.product_kit} setFieldValue={setFieldValue} clubId={clubId} />
                    </DialogContent>
                </TabPanel>
            </TabContext>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    // const { id: clubId } = useParams();
    const { data } = useFetch(`club/settings/plans/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#planos');

    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}
export default function ProductsClub() {
    const { id } = useParams();
    const { data, mutate } = useFetch(`club/settings/plans`);
    // console.log(data)

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#planos" variant="outlined">
                        Adicionar plano
                    </Button>
                </Stack>
                <Card>
                    <List>
                        {!!data && !Boolean(data.length) && (
                            <Paper sx={{ border: 0, p: 4 }}>
                                <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                                        <Typography>Nenhum resultado encontrado.</Typography>
                                        <Block />
                                </Stack>
                            </Paper>
                        )}
                        {data?.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#planos|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ borderRadius: 2, width: 90, mr: 3 }} src={item.image} />
                                    </ListItemAvatar>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}
