import { BlockOutlined, ChevronRight, DeleteSweepRounded, SearchRounded } from '@mui/icons-material';
import { Avatar, Button, ButtonGroup, Chip, IconButton, ListItemAvatar, ListItemText, Pagination, Paper, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFecth';
import DialogMigrate from '../../DialogMigrate';
import DialogAdvertiser from './components/DialogAdvertiser';
import { SearchDrawer } from './components/SearchDrawer';

export default function Advertiser() {
    const [searchParams, setParams] = useSearchParams();
    const [isDrawerOpen, setDrawerOpen] = useState(false); // Controle de estado do drawer

    const { data, mutate } = useFetch('advertiser', { params: { ...Object.fromEntries(searchParams) } });
    // console.log('aqui', data)

    const handleClearFilters = () => {
        setParams({});
    };

    const handlePage = (event, page) => {
        // Atualiza os parâmetros da URL, mantendo todos os outros e apenas mudando o parâmetro 'page'
        setParams(prevParams => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('page', page); // Define o número da página na URL
            return newParams;
        });
    };

    const hasFilters = Array.from(searchParams.entries()).some(([key, value]) => value !== '' && value !== null);



    return (
        <Container sx={{ my: 4 }}>
            <Stack py={3}>
                <Typography variant="h2">Anunciantes</Typography>
            </Stack>

            <Card>
                <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                    <Button variant="outlined" component={Link} to="#migration|advertiser">
                        Migrar anunciante
                    </Button>
                    <Button variant="outlined" component={Link} to="#advertiser">
                        Adicionar anunciante
                    </Button>
                    <ButtonGroup>
                        <Button color="primary" onClick={() => setDrawerOpen(true)}>
                            <SearchRounded />
                        </Button>
                        {hasFilters && (
                            <Button color="primary" onClick={handleClearFilters}>
                                <DeleteSweepRounded />
                            </Button>
                        )}
                    </ButtonGroup>
                </Stack>
                <Divider />

                <List size="md" sx={{ gap: 1 }}>
                    <>
                        {data?.rows?.length === 0 ? (
                            <Paper sx={{ p: 4, border: 0 }}>
                            <Stack direction="row" justifyContent="center" gap={1}>
                                <Typography>Nenhum anunciante encontrado.</Typography>
                                <BlockOutlined />
                            </Stack>
                        </Paper>
                        ) : (data?.rows?.map(item => (
                                <React.Fragment key={`list_${item.id}`}>
                                    <ListItem
                                        secondaryAction={
                                            <IconButton component={Link} to={`${item.id}`} title="Abrir">
                                                <ChevronRight />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                src={item.image}
                                                variant="rounded"
                                                sx={{ border: '1px solid #eee', objectFit: 'cover', width: 50, height: 30 }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.name}
                                            primaryTypographyProps={{ variant: 'title-md' }}
                                            secondary={
                                                <Stack columnGap={1} direction="row" py={1}>
                                                    <Chip
                                                        size="small"
                                                        color={item.status ? 'success' : 'error'}
                                                        label={item.status ? 'Ativo' : 'Inativo'}
                                                    />
                                                    <Chip size="small" color="primary" label={`${item.Branches?.length} filiais`} />
                                                    <Chip size="small" color="primary" label={`${item.Offer?.length} ofertas`} />
                                                </Stack>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        )}
                    </>
                </List>

                <Stack direction="row" py={2} justifyContent="center">
                    <Pagination page={data?.page || 1} count={data?.pages || 1} onChange={handlePage} />
                </Stack>
            </Card>

            <DialogAdvertiser mutate={mutate} />
            <DialogMigrate mutate={mutate} />
            <SearchDrawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)} onClearFilters={handleClearFilters} />
        </Container>
    );
}
