import React from 'react';
import { IMaskInput } from 'react-imask';

const PhoneInputMasked = React.forwardRef(function PhoneInputMasked(props, ref) {
  const { onChange, name, value, ...other } = props;

  return (
    <IMaskInput
      {...other}
      value={String(value)} // Garante que o valor seja uma string
      inputRef={ref}
      mask='(00) 00000-0000'
      overwrite
      unmask={true} // Retorna apenas os números sem a máscara
      lazy={true} // Não exibe a máscara até que o valor seja editado
      onAccept={(newValue) => {
        // Passando o evento com a estrutura esperada pelo React
        onChange({
          target: {
            name, // Nome do campo (phone)
            value: newValue, // Valor atualizado
          },
        });
      }}
    />
  );
});

export default PhoneInputMasked;
