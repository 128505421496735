import { DialogTitle, DialogActions, DialogContent, Dialog, FormControl, FormLabel, Switch, FormControlLabel, Divider, Typography, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Address from '../../../../components/Address';
import useErrors from '../../../../hooks/useErrors';
import server from '../../../../services/server';
import useDialog from '../../../../hooks/useDialog';
import { useFetch } from '../../../../hooks/useFecth';
import { useConfirm } from 'material-ui-confirm';
import SelectCategories from '../../../../components/SelectCategories';
import DocumentInputMasked from '../../../../components/DocumentInputMasked';
import AddImage from '../../../../components/AddImage';
import { Close } from '@mui/icons-material';

function Edit({ id, mutate }) {
    const { data } = useFetch(`advertiser/${id}`);
    return <FormBranch data={data} mutate={mutate} />;
}

function FormBranch({ data, mutate }) {
    const { id } = useParams();
    const {setErrors, enqueueSnackbar} = useErrors();
    const { open, handleClose } = useDialog('#filiais');

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            businessName: '',
            document: '',
            headOfficeId: id,
            city: '',
            state: '',
            status: '',
            // image: '',
            // zipcode: '',
            // street: '',
            // number: '',
            // complement: '',
            // neighborhood: '',
            // country: '',
            // phone: '',
            // areacode: '',
            // latitude: '',
            // longitude: '',
            // address: '',
            // remove: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (Boolean(values.remove)) {
                    setSubmitting(true);
                    await server.delete(`advertiser/${values.id}`, values);
                    enqueueSnackbar(`Filial ${values.name} removida`);

                    await mutate();
                    setSubmitting(false);
                    handleClose();
                    return;
                }

                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`advertiser`, values);
                    enqueueSnackbar(`Filial ${values.name} adicionada`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`advertiser/${values.id}`, values);
                    enqueueSnackbar(`Filial ${values.name} alterada`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const confirm = useConfirm();

    const handleRemove = async () => {
        try {

          // Exibe o diálogo de confirmação
          await confirm({
            title: 'Remover filial?',
            description: 'Essa ação é permanente',
          });
      
          // Se confirmado, executa as ações
          setFieldValue('remove', true);
          handleSubmit();
        } catch (error) {
            setErrors(error)
        }
      };
      

    useEffect(() => {
        if (data) {
            setValues({ ...data, address: `${data.street}, ${data.neighborhood}, ${data.city}-${data.state}` }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <DialogTitle>Adicionar/editar filial</DialogTitle>
                    {/* <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                        <Help />
                    </IconButton> */}
                    <IconButton size="small" onClick={handleClose} sx={{marginRight: 2}}>
                        <Close />
                    </IconButton>
                </Stack>
            <Divider />
            <DialogContent>
                <Stack gap={2} component="form" onSubmit={handleSubmit}>
                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <AddImage onChange={setFieldValue} name="image" value={values.previewImage || values.image} />
                        </>
                    )}
                    <TextField onChange={handleChange} label="Nome da filial" name="name" value={values.name} />
                    <TextField onChange={handleChange} label="Razão social" name="businessName" value={values.businessName} />
                    <TextField 
                    onChange={(event) => {setFieldValue('document', event.target?.value || event)}}
                    label="Documento" 
                    name="document" 
                    value={values.document}
                    InputProps={{
                        inputComponent: DocumentInputMasked
                    }} />

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <TextField onChange={handleChange} label="Website" name="website" value={values.website} />
                        </>
                    )}

                    <TextField onChange={handleChange} label="Nome do responsável" name="responsibleName" value={values.responsibleName} />
                    <TextField onChange={handleChange} label="Email do responsável" name="responsibleEmail" value={values.responsibleEmail} />

                    <Address
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        name="zipcode"
                        setFieldError={setFieldError}
                        value={values.zipcode}
                        address={values.address}
                    />
                    <Stack direction="row" columnGap={2}>
                        <TextField fullWidth onChange={handleChange} label="Complemento" name="complement" value={values.complement} />
                        <TextField sx={{ width: 150 }} onChange={handleChange} label="Número" name="number" value={values.number} />
                    </Stack>

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <SelectCategories value={values.listCategoryId} name="listCategoryId" onChange={setFieldValue} label="Selecione as categorias" />
                        </>
                    )}

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                label="Status anunciante"
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {Boolean(values.id) && (
                    <Button variant='outlined' color='error' onClick={handleRemove}>Excluir</Button>
                )}
                <Button variant="contained" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogBranch({ mutate }) {
    const { open, id } = useDialog('#filiais');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <FormBranch mutate={mutate} />;
}