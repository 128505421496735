import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import server from '../../services/server';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { useState } from 'react';
import { CardContent } from '@mui/material';
import useErrors from '../../hooks/useErrors';

export default function Auth() {
    const navigation = useNavigate();
    const {setErrors} = useErrors()

    const { values, errors, handleSubmit, handleChange } = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: async values => {
            try {
                const { data } = await server.post('/auth', values);
                navigation(`/auth/?token=${data.data.token}&refreshtoken=${data.data.refreshToken}`, { replace: true });
            } catch (error) {
                setErrors(error);
            }
        },
    });

    const handleLoginGoogle = async () => {
        try {
            window.open(`${process.env.REACT_APP_SERVER}auth/signin/google`, '_self');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Stack component="form" alignItems="center" justifyContent="center" backgroundColor="#eee" width="100vw" height="100vh" onSubmit={handleSubmit}>
            <Card sx={{ width: 300, textAlign: 'center' }}>
                <CardContent>
                    <Box component="img" mr="auto" ml="auto" pb={2} src={Logo} sx={{ width: 180 }} />
                    <Stack gap={2}>
                        <TextField variant='outlined' fullWidth value={values.username} label="Usuário" onChange={handleChange} name="username" />
                        <TextField variant='outlined' fullWidth value={values.password} type="password" label="Senha" onChange={handleChange} name="password" />
                        <Button fullWidth onClick={handleSubmit} type="submit" variant='contained'>
                            Entrar
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}
