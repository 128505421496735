import { Routes, Route, Outlet } from 'react-router-dom';
import Auth from './modules/Adm/Auth';
import AuthRedirect from './modules/AuthRedirect';
import Advertiser from './modules/Adm/Advertiser';
import Category from './modules/Adm/Category';
import Team from './modules/Adm/Team';
import User from './modules/Adm/User';
import Plans from './modules/Adm/Plans';
import Aditionals from './modules/Adm/Aditionals';
import AdmProtected from './modules/Adm/AdmProtected';
import Dashboard from './modules/Adm/Dashboard';
import ClubProtected from './modules/Club/ClubeProtected';
import ClubAuth from './modules/Club/ClubAuth';
import AdvertiserDetails from './modules/Adm/Advertiser/AdvertiserDetails';
import ClubsDetails from './modules/Adm/Clubs/ClubsDetails';
import Clubs from './modules/Adm/Clubs';

import Club from './modules/Club';
import ClubeSettings from './modules/Club/ClubeSettings';
 

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/auth" element={<AuthRedirect />} />

            <Route path="/login" element={<ClubAuth />} />
                        
            <Route path="dashboard" element={<AdmProtected />}>
                <Route index element={<Dashboard />} />
                <Route path="configuracoes" element={<Outlet />}>
                    <Route path="cargos" element={<Team />} />
                    <Route path="usuarios" element={<User />} />
                    <Route path="categorias" element={<Category />} />
                    <Route path="planos" element={<Plans />} />
                    <Route path="adicionais" element={<Aditionals />} />
                </Route>
                <Route path="anunciantes" element={<Outlet />}>
                    <Route index element={<Advertiser />} />
                    <Route path=":id" element={<AdvertiserDetails />} />
                </Route>
                <Route path="clubes" element={<Outlet />}>
                    <Route index element={<Clubs />} />
                    <Route path=":id" element={<ClubsDetails />} />
                </Route>
            </Route>

            <Route path="clube" element={<ClubProtected />}>
                <Route index element={<Club />} />
                <Route path="dashboard" element={<Club />} />
                <Route path="configuracoes" element={<Outlet />}>
                    <Route index element={<ClubeSettings />} />
                    <Route path="cargos" element={<Team />} />
                    <Route path="usuarios" element={<User />} />
                    <Route path="categorias" element={<Category />} />
                    <Route path="planos" element={<Plans />} />
                    <Route path="adicionais" element={<Aditionals />} />
                </Route>
                <Route path="anunciantes" element={<Outlet />}>
                    <Route index element={<Advertiser />} />
                    <Route path=":id" element={<AdvertiserDetails />} />
                </Route>
            </Route>
             
        </Routes>
    );
}
