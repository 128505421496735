import {DialogContent, DialogActions, DialogTitle} from '@mui/material';
import { useFormik } from 'formik';
import {Stack, TextField, Button, FormControl, FormHelperText, Switch, FormLabel, Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useErrors from '../../../../hooks/useErrors';
import server from '../../../../services/server';
import SelectTeam from '../../../../components/SelectTeam';
import { useAuth } from '../../../../context/AuthContext';
import { useCookies } from 'react-cookie';
import useDialog from '../../../../hooks/useDialog';
import { Close } from '@mui/icons-material';
import { useFetch } from '../../../../hooks/useFecth';
import ConfirmationModal from '../../../../components/ConfirmationModal';

function Edit({ id, mutate }) {
    const { data } = useFetch(`users/${id}`);
    return <Form data={data} mutate={mutate} />;
}


function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#users');
    const [{ user }] = useCookies();
    // Estado para controlar o modal de confirmação de exclusão
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const { setErrors, enqueueSnackbar } = useErrors();
    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            userName: '',
            roles: [],
            status: true,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`users`, values);
                    enqueueSnackbar(`Usuário ${values.userName} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`users/${values.id}`, values);
                    enqueueSnackbar(`Usuário ${values.userName} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const handleDeleteRequest = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setDeleteDialogOpen(false); // Fecha o modal
        if (!values.id) return;

        try {
            await server.delete(`users/${values.id}`);
            enqueueSnackbar(`Usuário ${values.userName} excluído`, { variant: 'success' });
            await mutate(); // Atualiza os dados após exclusão
            handleClose(); // Fecha o diálogo
        } catch (error) {
            setErrors(error);
        }
    };

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DialogTitle>{Boolean(data) ? 'Editando usuário' : 'Adicionando usuário'}</DialogTitle>
                        {Boolean(values.id) && <Typography>ID: #{values?.id}</Typography>}
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose} aria-label="close" sx={{ marginRight: 2 }}>
                            <Close />
                        </IconButton>
                    </Box>
                </Stack>

                <Divider />
                <DialogContent component="form" onSubmit={handleSubmit}>
                    <Stack rowGap={2}>
                        <FormControl>
                            <TextField
                                label="Usuário"
                                onChange={handleChange}
                                placeholder={`Usuário`}
                                value={values.userName}
                                name="userName"
                            />
                            <FormHelperText>User letras minúsculas sem espaços e sem caracteres especiais</FormHelperText>
                        </FormControl>
                        <TextField
                            label="Senha"
                            onChange={handleChange}
                            type="password"
                            inputProps={{ minLength: 6 }}
                            placeholder={`Senha`}
                            value={values.password}
                            name="password"
                        />
                        <SelectTeam
                            onChange={setFieldValue}
                            disabled={user?.id === values?.id}
                            placeholder="Cargo"
                            name="teamId"
                            value={values.teamId}
                        />
                        <FormControl orientation="horizontal">
                            <FormLabel>Status</FormLabel>
                            <Switch
                                checked={values.status}
                                disabled={user?.id === values?.id}
                                onChange={(e) => setFieldValue('status', e.target.checked)}
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    {Boolean(values.id) && (
                        <Box>
                            <Button variant="outlined" color="error" onClick={handleDeleteRequest}>
                                Excluir
                            </Button>
                        </Box>
                    )}
                    <Box>
                        <Button variant="contained" loading={isSubmitting} onClick={handleSubmit} type="submit">
                            Salvar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Modal de confirmação de exclusão */}
            <ConfirmationModal
                open={isDeleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteConfirm}
                title="Confirmação de Exclusão"
                message={
                    <>
                        Você deseja realmente excluir o usuário{' '}
                        <Typography component="span" fontWeight="bold">
                            {values.userName}
                        </Typography>
                        ?
                    </>
                }
            />
        </>
    );
}


export default function DialogUser({ mutate }) {
    const { open, id } = useDialog('#users');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
