import { ErrorOutlineRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Container, Icon, Paper, Typography } from '@mui/material';

export const ErrorBoundaryComponent = ({ error, resetErrorBoundary }) => {
    return (
        <Box width="100%" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" sx={{backgroundColor: '#efefef47'}}>
            <Paper
                variant="outlined"
                sx={{
                    rowGap: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 2,
                }}
            >
                <ErrorOutlineRounded fontSize="large" />
                <Typography variant="h5">Algo errado aconteceu</Typography>
                <Typography variant="body1">Nosso time já foi avisado</Typography>
                <Button variant="outlined" onClick={resetErrorBoundary}>
                    Voltar
                </Button>
            </Paper>
        </Box>
    );
};
