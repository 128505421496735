import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import Address from '../../../components/Address';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import { Alert, Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, Grid, Paper, Switch, Typography } from '@mui/material';
import SelectPlan from '../../../components/SelectPlan';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import { Link, useParams } from 'react-router-dom';
import SelectCategories from '../../../components/SelectCategories';
import SelectCategoriesList from '../../../components/SelectCategoriesList';
import SelectAdvertisers from '../../../components/SelectAdvertisers';

// function Edit({ id, mutate }) {
//     const { data } = useFetch(`clubs/${id}`);
//     return <Form data={data} mutate={mutate} />;
// }

// function Form({ data, mutate }) {
//     const { open, handleClose } = useDialog('#club');
//     const [errs, setErrs] = useState();
//     useErrors(errs);
//     const { enqueueSnackbar } = useSnackbar();

//     const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
//         initialValues: {
//             domain: '',
//             packageName: '',
//             appIntent: '',
//             primaryColor: '',
//             secundaryColor: '',
//             supportPhone: '',
//             supportEmail: '',
//         },
//         onSubmit: async (values, { setSubmitting }) => {
//             try {
//                 if (!Boolean(values.id)) {
//                     setSubmitting(true);
//                     await server.post(`club`, values);
//                     enqueueSnackbar(`Anunciante ${values.name} adicionado`);
//                 }

//                 if (Boolean(values.id)) {
//                     setSubmitting(true);
//                     await server.put(`clubs/${values.id}`, values);
//                     enqueueSnackbar(`Anunciante ${values.name} alterado`);
//                 }

//                 await mutate();
//                 setSubmitting(false);
//                 handleClose();
//             } catch (error) {
//                 setSubmitting(false);
//                 setErrs(error);
//             }
//         },
//     });

//     useEffect(() => {
//         if (data) {
//             console.log(data);
//             setValues({ ...data, address: `${data.street}, ${data.neighborhood}, ${data.city}-${data.state}` }, true);
//         }
//     }, [data]);

//     return (
//         <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//             <DialogTitle>Adicionando/editando clube</DialogTitle>
//             <Divider />
//             <DialogContent>
//                 <Stack gap={2} sx={{ overflow: 'scroll' }}>
//                     {Boolean(values.id) && (
//                         <>
//                             <AddImage name="logo" onChange={setFieldValue} value={values.previewImage || values.logo} />
//                         </>
//                     )}

//                     <TextField label="Domínio personalizado" onChange={handleChange} name="domain" value={values.domain} />

//                     <TextField label="Package Name app" onChange={handleChange} name="packageName" value={values.packageName} />

//                     <TextField
//                         label="Documento do responsável pelo clube"
//                         onChange={handleChange}
//                         placeholder="Documento do dono clube"
//                         name="Owner.document"
//                         value={values.Owner?.document}
//                     />

//                     <TextField
//                         label="Telefone do responsável pelo clube"
//                         onChange={handleChange}
//                         placeholder="Telefone do clube"
//                         name="Owner.phone"
//                         value={values.Owner?.phone}
//                     />

//                     <TextField
//                         label="Aniversário do responsável pelo clube"
//                         onChange={handleChange}
//                         placeholder="Aniversário"
//                         name="Owner.birthday"
//                         type="date"
//                         value={values.Owner?.birthday}
//                     />

//                     <Divider />

//                     <TextField label="Nome do clube" onChange={handleChange} placeholder="Nome do clube" name="name" value={values.name} />

//                     {Boolean(values.id) && (
//                         <TextField
//                             label="Razão Social"
//                             onChange={handleChange}
//                             placeholder="Razão social"
//                             name="businessName"
//                             value={values.businessName}
//                         />
//                     )}

//                     <TextField label="Documento" onChange={handleChange} placeholder="Documento" name="document" value={values.document} />

//                     <Address
//                         onChange={handleChange}
//                         setFieldValue={setFieldValue}
//                         name="zipcode"
//                         setFieldError={setFieldError}
//                         value={values.zipcode}
//                         address={values.address}
//                     />

//                     <TextField label="Complemento" onChange={handleChange} placeholder="Complemento" name="complement" value={values.complement} />

//                     <SelectPlan name="planId" value={values.planId} onChange={setFieldValue} />

//                     {Boolean(values.id) && (
//                         <>
//                             <FormControlLabel
//                                 control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
//                                 orientation="horizontal"
//                                 label="Status do clube"
//                             />
//                         </>
//                     )}
//                 </Stack>
//             </DialogContent>
//             <Divider />
//             <DialogActions>
//                 <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
//                     Salvar e continuar
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// }

function DialogDesign({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#personalizar');
    const [errs, setErrs] = useState();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, handleChange, setFieldValue, setValues, isSubmitting } = useFormik({
        initialValues: {
            primaryColor: '#ffffff',
            secondaryColor: '',
            logo: '',
            id: clubId,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`clubs/${values.id}`, values);
                    enqueueSnackbar(`Dados salvos`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { primaryColor, secondaryColor, logo, id } = data;
            setValues({ primaryColor, secondaryColor, logo, id }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando clube</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    {Boolean(values.id) && (
                        <>
                            <AddImage
                                name="logo"
                                onChange={setFieldValue}
                                value={values.previewImage || values.logo}
                                canvaWidth={200}
                                canvaHeight={120}
                            />
                        </>
                    )}

                    <TextField label="Selecione a cor do seu clube" type="color" name="primaryColor" onChange={handleChange} value={values.primaryColor} />
                    <Alert>Lembre-se de escolher uma cor e o logo de modo que o contraste seja compatível</Alert>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogSegments({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#segmentacao');

    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            categoryIds: [],
            id: clubId,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`clubs/${clubId}/segment`, values);
                enqueueSnackbar(`Segmentação salva`);

                await mutate();
                setSubmitting(false);
                //handleClose();
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { ClubCategory } = data;
            setValues({ categoryIds: ClubCategory.map(i => i.categoryId) }, true);
        }
    }, [data, open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando clube</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    {/* <SelectCategories value={values.categoryIds} name="categoryIds" onChange={setFieldValue} label="Selecione as categorias" /> */}

                    <SelectCategoriesList onChange={setFieldValue} value={values.categoryIds} name="categoryIds" />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogSegmentsExclude({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#excluidas');

    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            excludeCategory: [],
            excludeAdvertiser: [],
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`clubs/${clubId}`, values);
                enqueueSnackbar(`Categorias excluídas alteradas`);

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { excludeCategory, excludeAdvertiser } = data;
            setValues({ excludeCategory, excludeAdvertiser }, true);
        }
    }, [data, open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Excluindo</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <SelectCategories
                        value={values.excludeCategory}
                        name="excludeCategory"
                        onChange={setFieldValue}
                        label="Selecione as categorias"
                    />
                    <SelectAdvertisers
                        value={values.excludeAdvertiser}
                        name="excludeAdvertiser"
                        onChange={setFieldValue}
                        label="Selecione os anunciantes"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DialogApp({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#app');

    const { setErrors, enqueueSnackbar } = useErrors();

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            appIntent: '',
            packageName: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.put(`clubs/${clubId}`, values);
                enqueueSnackbar(`Parametros alterados`);

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            const { appIntent, packageName } = data;
            setValues({ appIntent, packageName }, true);
        }
    }, [data, open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Parametros do aplicativo</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField name="appIntent" label="App Intent" value={values.appIntent} onChange={handleChange} />
                    <TextField name="packageName" label="Package Name" value={values.packageName} onChange={handleChange} />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default function SettingsClub({ data, mutate }) {
    return (
        <Paper>
            <Divider />
            <Grid container spacing={2} p={2}>
                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Logo e cores" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: data.primaryColor }}>
                            <Stack rowGap={1} textAlign="center" alignItems="center">
                                <Box component="img" src={data.logo} sx={{ width: 200, height: 'auto' }} />
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button component={Link} to="#personalizar" variant="outlined">
                                {!!data.logo ? 'Alterar' : 'Adicionar'}
                            </Button>
                        </CardActions>
                    </Card>
                    <DialogDesign data={data} mutate={mutate} />
                </Grid>

                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Segmentação" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack rowGap={1}>
                                    <Typography variant="title-sm">Categorias</Typography>
                                    <Typography variant="title-md">{data?.ClubCategory.length || 'Clube Global'}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <Divider />
                        {/* <CardActions>
                            <Button variant="outlined" component={Link} to="#segmentacao">
                                Alterar
                            </Button>
                            <Button variant="outlined" color="secondary" component={Link} to="#excluidas">
                                Sem interesse
                            </Button>
                        </CardActions> */}
                    </Card>
                    <DialogSegments data={data} mutate={mutate} />
                    <DialogSegmentsExclude data={data} mutate={mutate} />
                </Grid>

                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Domínio personalizado" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Stack rowGap={1}>
                                <Typography variant="title-sm">Seu domínio</Typography>
                                <Typography variant="title-md">{data.domain}</Typography>
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Chip label="Pendente" color="warning" />
                            {!!data.domain ? (
                                <>
                                    <Button variant="contained">Verificar</Button>
                                    <Button variant="outlined">Alterar</Button>
                                </>
                            ) : (
                                <Button variant="outlined">Adicionar domínio</Button>
                            )}
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Aplicativo" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Stack rowGap={1}>
                                <Typography variant="title-sm">{data.packageName || '--'}</Typography>
                                <Typography variant="title-sm">{data.appIntent || '--'}</Typography>
                            </Stack>
                        </CardContent>
                        <Divider />
                        {/* <CardActions>
                            <Button component={Link} to="#app" variant="outlined">
                                Alterar
                            </Button>
                        </CardActions> */}
                    </Card>
                    <DialogApp data={data} mutate={mutate} />
                </Grid>

                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Google Analytics" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack rowGap={1}>
                                    <Typography variant="title-sm">Seu identificador</Typography>
                                    <Typography variant="title-md">{data.analitycs || '--'}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            {!!data.analitycs ? <Button variant="outlined">Alterar</Button> : <Button variant="outlined">Ativar</Button>}
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item md={4}>
                    <Card sx={{ minWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardHeader title="Integrador de pagamentos - Asaas" />
                        <Divider />
                        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack rowGap={1}>
                                    <Typography variant="title-sm">Situação</Typography>
                                    <Typography variant="title-md">{data.assasKey || '--'}</Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            {!!data.analitycs ? <Button variant="outlined">Alterar</Button> : <Button variant="outlined">Ativar</Button>}
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    );
}
