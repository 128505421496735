import React, { useEffect } from 'react';
import { Box, Drawer, Button, Stack, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import PhoneInputMasked from '../../../../components/PhoneInputMasked';
import DocumentInputMasked from '../../../../components/DocumentInputMasked';

export function SearchDrawerSubscribers({ open, onClose, onClearFilters }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const { values, handleSubmit, setFieldValue, handleChange, resetForm } = useFormik({
        initialValues: {
            document: searchParams.get('document') || '',
            name: searchParams.get('name') || '',
            status: searchParams.get('status') || '', // Status como string "true" ou "false"
            // city: searchParams.get('city') || '',
            phone: searchParams.get('phone') || '',
        },
        onSubmit: (values) => {
            const filteredParams = Object.fromEntries(
                Object.entries(values).filter(([key, value]) => value !== '' && value !== null)
            );

            if (values.status) {
                filteredParams.status = values.status === 'true';
            }

            console.log("Parâmetros enviados:", filteredParams); // Diagnóstico
            setSearchParams(filteredParams);
            onClose();
        }
    });

    useEffect(() => {
        resetForm({
            values: {
                document: searchParams.get('document') || '',
                name: searchParams.get('name') || '',
                status: searchParams.get('status') || '',
                // city: searchParams.get('city') || '',
                phone: searchParams.get('phone') || '',
            },
        });
    }, [searchParams, resetForm]);

    const handleClearFilters = () => {
        onClearFilters();
        setSearchParams({});
        resetForm();
    };

    const hasFilters = Object.values(values).some(value => value !== '' && value !== null);

    const list = () => (
        <Box sx={{ padding: 2, width: 350 }}>
            <DialogTitle sx={{ padding: 0 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                    Buscar Assinantes
                    <IconButton size="small" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField label="Nome do assinante" variant="outlined" fullWidth name="name" value={values.name} onChange={handleChange} />
                    <TextField
                        fullWidth
                        label="Telefone"
                        variant="outlined"
                        name="phone"
                        value={values.phone}
                        onChange={(event) => {setFieldValue('phone', event.target?.value || event);}}
                        InputProps={{
                            inputComponent: PhoneInputMasked,
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Documento"
                        variant="outlined"
                        name="document"
                        value={values.document}
                        onChange={(event) => {setFieldValue('document', event.target?.value || event)}}
                        InputProps={{
                            inputComponent: DocumentInputMasked,
                        }}
                    />
                    <FormControl fullWidth size="small" margin="dense">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select labelId="status-label" id="status" name="status" value={values.status} onChange={handleChange} label="Status">
                            <MenuItem value="true">Ativo</MenuItem>
                            <MenuItem value="false">Inativo</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <TextField
                        label="Cidade"
                        variant="outlined"
                        fullWidth
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                    /> */}
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="contained" type="submit">
                        Buscar
                    </Button>
                    {hasFilters && (
                        <Button variant="outlined" onClick={handleClearFilters}>
                            Limpar Filtros
                        </Button>
                    )}
                </Stack>
            </form>
        </Box>
    );

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            {list()}
        </Drawer>
    );
}
